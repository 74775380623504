<div class="outter-wrapper d-flex flex-column justify-content-center position-relative pricing">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative">
                Ready To Experience Your <span class="stand-out-word">Perfect</span> Website
            </div>
            <!-- <span class="dash"></span> -->
            <div class="d-flex justify-content-center content mt-5 mb-3 realtor-description">
                <p>
                    We offer the perfect website for any budget and branding needs.
                </p>
            </div>

            <div class="d-flex card-outter-wrapper">
                <ng-container *ngFor="let card of packages; let i = index">
                    <div
                        class="d-flex flex-column justify-content-center align-items-center card-wrapper position-relative">

                        <div *ngIf="i == 1" class="text-uppercase popular-container">Most Popular</div>

                        <div class="card-title text-uppercase">
                            {{ card.name }}
                        </div>

                        <div class="card-description d-flex justify-content-center">
                            {{ card.description }}
                        </div>

                        <div class="d-flex flex-column justify-content-center align-items-center card-price-wrapper">
                            <div class="card-total-price">
                                ${{ card.price }}
                            </div>
                            <div class="card-monthly-price text-uppercase">
                                + ${{ card.monthly }} per month
                            </div>
                        </div>


                        <div class="d-flex justify-content-center align-items-center card-package-wrapper">
                            <div class="d-flex flex-column justify-content-center">
                                <div *ngFor="let item of card.packageDetail; let i = index"><span
                                        class="card-content-bullet">+</span><span>{{ card.packageDetail[i] }}</span>
                                </div>
                                <!-- <div><span class="card-content-bullet">+</span><span>{{ card.packageDetail[1] }}</span>
                                </div>
                                <div><span class="card-content-bullet">+</span><span>{{ card.packageDetail[2] }}</span>
                                </div>
                                <div><span class="card-content-bullet">+</span><span>{{ card.packageDetail[3] }}</span>
                                </div>
                                <div><span class="card-content-bullet">+</span><span>{{ card.packageDetail[3] }}</span>
                                </div> -->
                                <div class="d-flex justify-content-center mt-4"><button mat-flat-button
                                        (click)="scroll()" class="book-demo" color="primary">Book Demo</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>


        </div>
    </div>

    <span class="dash" class="dash"></span>

    <app-contact-us id="target" #target class="contact-us-wrapper"
        [showMap]="environment.enableGoogleMaps"></app-contact-us>

</div>

<app-footer class="footer"></app-footer>