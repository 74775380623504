import { Component } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { FooterComponent } from '../footer/footer.component';
import { environment } from '../../env/environment';
import { AppServiceService } from '../services/app-service.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-unsubscribe',
  standalone: true,
  imports: [
    ContactUsComponent,
    FooterComponent,
  ],
  templateUrl: './unsubscribe.component.html',
  styleUrl: './unsubscribe.component.scss'
})
export class UnsubscribeComponent {
  environment = environment;
  url;
  session;
  subscriberId;
  subscriberEmail;
  subscriberDomain;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    .set('Content-Type', `application/json`);

  constructor(
    public authService: AuthService,
    private httpClient: HttpClient,
    public appService: AppServiceService,
    private router: Router,
    private route: ActivatedRoute,
  ) {
    this.url = `${environment.rootUrl}prod/subscribers/`;
  }

  async ngOnInit() {
    // this.session = await this.authService.getCurrentSession();

    this.route.queryParams.subscribe(params => {
      this.subscriberId = params['id'];
      // this.subscriberDomain = params['domain'];
      // this.subscriberDomain = environment.domainName;

      console.log('Query Parameter Value:', this.subscriberId);

      this.url = `https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/prod/subscribers?id=${this.subscriberId}&type=DELETE`

      // this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${this.session?.idToken?.toString()}`)
      this.httpClient.get(this.url, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          console.log(data)
        }
      )
    });
  }

}
