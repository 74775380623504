import { Component, OnInit, HostListener, ChangeDetectorRef, ViewChild, ElementRef, } from '@angular/core';
import { CommonModule, Location } from '@angular/common';
import { ActivatedRoute, RouterOutlet, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subject, interval } from 'rxjs';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { CookieConsentComponent } from './cookie-consent/cookie-consent.component';
import { ENUMS } from '../models/constants';
import { environment } from '../env/environment';
import { AppServiceService } from './services/app-service.service';
import { TranslateService } from '@ngx-translate/core';
import { Amplify } from 'aws-amplify';
import { Hub } from "aws-amplify/utils";
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { signInWithRedirect, getCurrentUser, AuthUser } from "aws-amplify/auth";
import { AuthService } from './services/auth.service';
import { GoogleAnalyticsService } from './services/google-analytics.service';
import { filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    CommonModule,
    RouterOutlet,
    HeaderComponent,
    FooterComponent,
    CookieConsentComponent,
    AmplifyAuthenticatorModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {

  // @HostListener('window:mousemove', ['$event'])
  // onMousemove(event: MouseEvent): void {
  //   console.log('User is moving the mouse');
  //   this.authService.checkTokenExpired();
  // }

  @HostListener('window:touchstart', ['$event'])
  onTouchstart(event: TouchEvent): void {
    console.log('User touched the screen');
    // if (this.authenticated) {
    // console.log("User logged in, checking token expriation")
    this.authService.checkTokenExpiredManually(event);
    // }
  }

  @HostListener('window:click', ['$event'])
  onClick(event: Event): void {
    // console.log('User clicked somewhere on the page');
    // if (this.authenticated) {
    // console.log("User logged in, checking token expriation")
    this.authService.checkTokenExpiredManually(event);
    // }
  }

  @HostListener('window:keydown', ['$event'])
  onKeydown(event: KeyboardEvent): void {
    // console.log(`User pressed a key: ${event.key}`);
    // if (this.authenticated) {
    // console.log("User logged in, checking token expriation")
    this.authService.checkTokenExpiredManually(event);
    // }
  }

  title = 'reSiteGenerate';
  showFooter = true;
  environment = environment;
  ENUMS = ENUMS;

  email: any;
  phone: any;

  showHeader = false;
  pagesWithoutHeaders = ['properties', 'login'];
  authenticated: boolean = false;
  hostname = '';

  showHomeSpotterBanner = false;

  private routeSub!: Subscription;

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
    .set('Content-Type', `application/json`);

  usersUrl = `${environment.rootUrl}prod/users/`;
  session: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public appService: AppServiceService,
    private translate: TranslateService,
    private authService: AuthService,
    private _location: Location,
    private gaService: GoogleAnalyticsService,
    private cdRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private httpClient: HttpClient,
  ) {
    this.appService.checkMobile();

    this.translate.setDefaultLang('en');

    this.router.events.subscribe((event: any) => {
      // if (event && event.routeConfig && event.routeConfig.path) {
      //   // this.isLogin = event.routeConfig.path.includes('login');
      //   this.showHomeSpotterBanner = event.routeConfig.path.includes('search-property');
      // }

      if (event && event.url) {
        // this.isLogin = event.url.includes('login');
        this.showHeader = !this.pagesWithoutHeaders.some(p => event.url.includes(p))
        console.log('showHeader: ' + this.showHeader)
      }
    })

    // this.hostname = window.location.hostname;
    if (environment.isLocalhost) {
      this.hostname = 'http://localhost:4200';
    } else {
      // if (environment.envType === ENUMS.HOUSEHUB_SITE) {
      //   this.hostname = 'https://www.househubnc.com';
      //   // this.hostname = 'https://www.auth.househubnc.com';
      // } else if (environment.envType === ENUMS.UNIVERSAL_REALTY_SITE) {
      //   this.hostname = 'https://d1tx00hc5dhoug.cloudfront.net';
      // } else if (environment.envType === ENUMS.DEMO_SITE) {
      //   this.hostname = 'https://d364im3l3yp3v2.cloudfront.net';
      // }
      this.hostname = environment.hostname;
    }
    console.log(`hostname: ${this.hostname}`)

    Amplify.configure({
      Auth: {
        Cognito: {
          loginWith: {
            oauth: {
              // HH (Used in Google Consolel redirect URI: https://console.cloud.google.com/apis/credentials/oauthclient/948838363071-8ab8oadld73pas9obt8brs2itmba0lu5.apps.googleusercontent.com?authuser=2&project=moonlit-web-422414-e7)
              // (Source: Cognito User Pool Domain)
              // domain: "househub-prod.auth.us-east-1.amazoncognito.com",
              domain: "auth.househubnc.com",

              // UR
              // domain: "hhamplifybf7f3e6a-bf7f3e6a-uramplify.auth.us-east-1.amazoncognito.com",
              scopes: [
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin"
              ],
              // redirectSignIn: [],
              // redirectSignOut: [],
              redirectSignIn: [`${this.hostname}/my-profile/`],
              redirectSignOut: [`${this.hostname}/login/`],
              responseType: "code"
            },
          },
          // HH
          userPoolId: 'us-east-1_2XsRspLv1',
          userPoolClientId: '1rftika8cqr5adelj1lbvru8n3'

          // UR
          // userPoolId: 'us-east-1_tsO7CkZQh',
          // userPoolClientId: '42l7on5pmten61aom63aor1frh'
        }
      }
    });
  }

  // checkSessionEndTime() {
  //   let endTime = localStorage.getItem('sessionEndTime');
  //   let timeNow = (new Date()).getTime();
  //   if (endTime) {
  //     if (Number(endTime) <= timeNow) {
  //       // localStorage.removeItem('sessionEndTime');
  //       this.authService.signOut();
  //     }
  //   }
  // }

  async ngOnInit() {
    this.session = await this.authService.getCurrentSession();

    this.translate.use('en');

    this.authenticated = await this.authService.isAuthenticated();

    if (this.authenticated) {
      this.authService.setSessionTimeout();
    }

    this.email = environment.clientEmail;
    this.phone = environment.clientPhone;

    // this.showHomeSpotterBanner = this.router.url.includes('search-property');
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.showHomeSpotterBanner = event.url.includes('search-property');
    })

    // interval(1000 * 5).subscribe((val) => {
    //   this.checkSessionEndTime()
    //   console.log("interval")
    // })

    // if (!environment.isLocalhost) {
    //   setInterval(() => checkSessionEndTime(), 1000 * 60 * 30)

    //   let that = this;
    //   function checkSessionEndTime() {
    //     if (typeof localStorage !== 'undefined' && that.authenticated) {
    //       let endTime = localStorage.getItem('sessionEndTime');
    //       let timeNow = (new Date()).getTime();
    //       if (endTime) {
    //         if (Number(endTime) <= timeNow) {
    //           // localStorage.removeItem('sessionEndTime');
    //           that.authService.signOut();
    //         }
    //       }
    //     }
    //   }
    // }

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.dialog.closeAll(); // Close dialogs on route change.
      }
    });

    Hub.listen("auth", ({ payload }) => {
      switch (payload.event) {
        case "signInWithRedirect":
        case "signedIn":
          getCurrentUser().then(user => {
            console.log(`user is: ${JSON.stringify(user)}`);

            this.authService.getCurrentSession().then((session) => {
              this.authService.currentGroups().then(groups => {
                if (!groups || groups.length === 0) {
                  let data = {
                    onlyAddDomainGroup: true,
                  }

                  this.httpHeaders = this.httpHeaders.set('Authorization', `Bearer ${session?.idToken?.toString()}`)
                  this.httpClient.put(this.usersUrl, data, { headers: this.httpHeaders, observe: 'response' }).subscribe(
                    (data: any) => {
                      console.log('Only add group to user response: ' + data.body)

                    }, (error) => {
                      console.log(JSON.stringify(error))
                    }
                  )
                }
              })
            });


            // Only if non-social login user do we check if user is using preferred username
            // (one if they changed from initial registered email)
            this.authService.isSocialLoggedInUser().then(isSocialLoggedInUser => {
              if (!isSocialLoggedInUser) {
                this.gaService.sendGAActionEvent(`login-username-pw`);

                this.authService.isCurrentUserUsingPreferredUsername().then((userPreferred: any) => {
                  if (!userPreferred.preferred) {
                    this.authService.signOut();
                    return;
                  }

                  // localStorage.setItem('sessionEndTime', (new Date()).getTime() + 1000 * 3 + '');

                  setTimeout(() => {
                    this.authService.isInGroup(ENUMS.LANDLORD_GROUP).then((isLandlord: any) => {
                      if (isLandlord) {
                        // location.reload();

                        setTimeout(() => {
                          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
                            setTimeout(() => {
                              if (this.appService.urlSelectedVendor) {
                                // Initially going to assign vendor but user need to login first, now logged in, we can send vendor email
                                this.router.navigate(['/properties'], {
                                  queryParams: {
                                    vendor: this.appService.urlSelectedVendor,
                                    woId: this.appService.urlSelectedWoId,
                                    address: this.appService.urlSelectedAddress,
                                  }
                                });
                              } else {
                                // this.router.navigate(['/properties']);
                                this.router.navigate(['/my-profile']);
                              }
                            }, 1000);
                          });
                        }, 200);
                      } else {
                        location.reload();
                      }
                    })

                    // this.authService.setSessionTimeout();
                  }, 800)


                })
              } else {
                this.gaService.sendGAActionEvent(`login-social-provider`);

                // localStorage.setItem('sessionEndTime', (new Date()).getTime() + 1000 * 3 + '');

                setTimeout(() => {
                  // this.router.navigate(['my-profile']);
                  location.reload();
                  // this.authService.setSessionTimeout();
                }, 500)
              }
            })
          });
          break;
        case "signInWithRedirect_failure":
          // handle sign in failure
          break;
        case "customOAuthState":
          const state = payload.data; // this will be customState provided on signInWithRedirect function
          console.log(state);
          break;
        case "signedOut":
          // setTimeout(() => {
          // this.router.navigate(['login']);

          // this._location.back();
          location.reload();
          // }, 800)
          break;
      }
    });

    function handleSignInClick(customState: string) {
      signInWithRedirect({
        provider: "Google",
        customState
      });
    }



    // this.appService.getData().subscribe((data: any) => {
    //   if (data && data.type === 'show-footer') {
    //     this.showFooter = data.value;
    //   }
    // })

    // if (this.route.snapshot.url[0] && this.route.snapshot.url[0].path === 'search-property') {
    //   this.showFooter = false;
    // }

    // if (this.router.url === 'search-property') {
    //   this.showFooter = false;
    // }

    // this.route.url.subscribe((event: any) => {
    //   if (event[0].path === 'search-property') {
    //     this.showFooter = false;
    //   }
    // })
  }
}
