<div *ngIf="environment.envType !== ENUMS.HOUSEHUB_SITE">
    <div style="position: relative">
        <app-jumbotron-video [videoUrl]="pathToJumbotronVideo"></app-jumbotron-video>

        <app-about-us></app-about-us>

        <app-featured-properties></app-featured-properties>

        <app-contact-us class="contact-us" [showMap]="environment.enableGoogleMaps"></app-contact-us>
    </div>

    <app-footer class="footer"></app-footer>
</div>

<div *ngIf="environment.envType === ENUMS.HOUSEHUB_SITE">
    <div style="position: relative">
        <app-hh-jumbotron></app-hh-jumbotron>

        <app-contact-us class="contact-us" [showMap]="environment.enableGoogleMaps"></app-contact-us>

        <app-footer class="footer"></app-footer>
    </div>
</div>