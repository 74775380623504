<div *ngIf="showHomeSpotterBanner" class="home-spotter-app-banner d-flex justify-content-center">
  <div>Listings here are from our firm Universal Realty only. To see all listings, please download our easy to use app
    <a target="_blank" href="https://apps.apple.com/us/app/homespotter-real-estate/id958764301">HomeSpotter</a>.
  </div>
</div>

<app-header *ngIf="showHeader"></app-header>

<router-outlet />

<app-cookie-consent></app-cookie-consent>

<div *ngIf="appService.isMobile && !authenticated" class="email-icon"
  [class]="{'only-email': environment.envType === ENUMS.HOUSEHUB_SITE}">
  <a href="mailto:{{email}}"><img src="assets/images/email-circled.png" width="50" height="50px" /></a>
</div>

<div
  *ngIf="appService.isMobile && environment.envType !== ENUMS.HOUSEHUB_SITE && environment.envType !== ENUMS.PRO_REALTY_SITE && !authenticated"
  class="phone-icon">
  <a href="tel:{{phone}}"><img src="assets/images/phone-circled2.png" width="50" height="50px" /></a>
</div>