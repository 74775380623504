import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-terms-of-service',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
  ],
  templateUrl: './terms-of-service.component.html',
  styleUrl: './terms-of-service.component.scss'
})
export class TermsOfServiceComponent {

  env = environment;
  ENUMS = ENUMS;

  constructor(
    private appService: AppServiceService,
    public authService: AuthService,
  ) {
    this.authService.checkRoute();
  }

  ngOnInit() {
    this.appService.scrollToTop();
  }

}
