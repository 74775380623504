import { Component, OnInit } from '@angular/core';
import { environment } from '../../env/environment';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';

@Component({
  selector: 'app-property-management',
  standalone: true,
  imports: [
    ContactUsComponent,
    FooterComponent,
  ],
  templateUrl: './property-management.component.html',
  styleUrl: './property-management.component.scss'
})
export class PropertyManagementComponent implements OnInit {

  environment = environment;

  constructor(
    private appService: AppServiceService,
  ) {
  }

  ngOnInit() {
    this.appService.scrollToTop();
  }

}
