import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { environment } from '../../env/environment';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'app-sellers-process',
  standalone: true,
  imports: [
    CommonModule,
    ContactUsComponent,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FooterComponent,
  ],
  templateUrl: './sellers-process.component.html',
  styleUrl: './sellers-process.component.scss'
})
export class SellersProcessComponent implements OnInit {

  environment = environment;

  constructor(
    private appService: AppServiceService,
    private authService: AuthService,
  ) {
    this.authService.checkRoute();
  }

  ngOnInit(): void {
    this.appService.scrollToTop();
  }

}
