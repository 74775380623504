<div class="hero-image">
    <img src="{{imageUrl}}" class="img" />

    <div class="main-title">
        <div *ngIf="!appService.isMobile" class="arrow">></div>
        <div class="main-content"> {{ "Brand Experience" | translate }}</div>
        <div class="main-content">{{ "Is Our Expertise" | translate }}</div>
    </div>

    <div *ngIf="locale === 'English'" class="expertise-wrapper pt-5">
        <div class="d-flex justify-content-center align-items-center" [class]="{'flex-column': appService.isMobile}">
            <div class="expertise-card">
                <div class="expertise-card-title">Brand Interface Design</div>
                <div class="expertise-card-content">Deliver elegant user experience across platforms - mobile, web.
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">Experience Design</div>
                <div class="expertise-card-content">Our holistic user-centered approach produces natural user journey.
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">Brand Identity</div>
                <div class="expertise-card-content">(Re)define your brand with indepth understanding of the market,
                    target audience, and competitors.
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">Content & Conversion</div>
                <div class="expertise-card-content">Optimize content and information architecture for SEO and
                    conversion.
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="locale === '简体中文'" class="expertise-wrapper pt-5">
        <div class="d-flex justify-content-center align-items-center" [class]="{'flex-column': appService.isMobile}">
            <div class="expertise-card">
                <div class="expertise-card-title">品牌界面设计</div>
                <div class="expertise-card-content">品牌界面设计跨平台（移动、网络）提供优雅的用户体验。
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">
                    体验设计</div>
                <div class="expertise-card-content">我们以用户为中心的整体方法产生自然的用户旅程。
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">品牌形象</div>
                <div class="expertise-card-content">通过对市场的深入了解（重新）定义您的品牌，目标受众和竞争对手。
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">内容与转化
                </div>
                <div class="expertise-card-content">优化内容和信息架构以进行搜索引擎优化和转换。
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="locale === '繁体中文'" class="expertise-wrapper pt-5">
        <div class="d-flex justify-content-center align-items-center" [class]="{'flex-column': appService.isMobile}">
            <div class="expertise-card">
                <div class="expertise-card-title">
                    品牌介面設計</div>
                <div class="expertise-card-content">跨平台（行動、網路）提供優雅的使用者體驗。
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">
                    體驗設計</div>
                <div class="expertise-card-content">我們以用戶為中心的整體方法產生自然的用戶旅程。
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">品牌形象</div>
                <div class="expertise-card-content">透過對市場的深入了解（重新）定義您的品牌，
                    目標受眾和競爭對手。
                </div>
            </div>

            <div class="expertise-card">
                <div class="expertise-card-title">內容與轉化
                </div>
                <div class="expertise-card-content">優化內容和資訊架構以進行搜尋引擎優化和轉換。
                </div>
            </div>
        </div>
    </div>
</div>