<div *ngIf="showConsentBanner" id="cookie-consent-banner" class="cookie-consent-banner">
    <div class="d-flex justify-content-start align-items-end">
        <img src="assets/images/cookie-light.png" width="45px" height="45px" />
        <h3 class="ml-3">Cookie settings</h3>
    </div>
    <p>We use cookies to provide you with the best possible experience. They also allow us to analyze user behavior in
        order to constantly improve the website for you. By clicking “Accept All", you agree {{ env.clientName }} can
        store cookies on your device and disclose information in accordance with our <a [routerLink]="'/privacy-policy'"
            style="color: white; text-decoration: underline !important">Privacy Policy</a></p>
    <button id="btn-accept-all" (click)="acceptAll()" class="cookie-consent-button btn btn-primary">Accept All</button>
    <button id="btn-accept-some" (click)="acceptSome()" class="cookie-consent-button ml-2 btn btn-primary">Accept
        Selection</button>
    <button id="btn-reject-all" (click)="rejectAll()" class="cookie-consent-button ml-2 btn btn-outline-primary">Reject
        All</button>
    <div class="cookie-consent-options">
        <label><input id="consent-necessary" type="checkbox" value="Necessary" checked disabled><span
                class="ml-1">Necessary</span></label>
        <label class="ml-2"><input id="consent-analytics" type="checkbox" value="Analytics"
                (change)="updateSelection('Analytics', $event)" [checked]="userSelection.Analytics"><span
                class="ml-1">Analytics</span></label>
        <label class="ml-2"><input id="consent-preferences" type="checkbox" value="Preferences"
                (change)="updateSelection('Preferences', $event)" [checked]="userSelection.Preferences"><span
                class="ml-1">Preferences</span></label>
        <label class="ml-2"><input id="consent-marketing" type="checkbox" value="Marketing"
                (change)="updateSelection('Marketing', $event)" [checked]="userSelection.Marketing"><span
                class="ml-1">Marketing</span></label>
    </div>
</div>

<div *ngIf="!showConsentBanner" (click)="showConsentBanner = true;" class="cookie-consent-icon">
    <img src="assets/images/cookie.png" width="25px" height="25px" />
</div>