<div class="featured-properties-outter-wrapper pt-5 pb-5">
    <h2 class="mb-5 featured-main-title relative">{{ "Featured Properties" | translate }}</h2>
    <span class="dash"></span>

    <!-- <div *ngFor="let property of propertyDetails">
        <app-property-card [propertyDetail]="property"></app-property-card>
    </div> -->

    <ngx-slick-carousel class="carousel" #slickCarousel="slick-carousel" [config]="slideConfig">
        @for (property of propertyDetails; track property.ListingId) {
        <div ngxSlickItem class="slide">
            <!-- <img fill src="{{ slide.img }}" class="carousel-img" alt="" width="100%"> -->
            <app-property-card [propertyDetail]="property"></app-property-card>
        </div>
        }
    </ngx-slick-carousel>
</div>