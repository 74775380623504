<div class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative">
                Unsubscription
            </div>
            <span class="dash"></span>
            <div class="content mt-5 mb-4 realtor-description">
                <h3>Your request has been processed.</h3>
                <h1 class="mt-5" style="font-weight: 800">We can help you find the DREAM HOME you deserve!</h1>
                <h2>Looking forward to working with you!</h2>
            </div>
        </div>
    </div>

    <span class="dash"></span>

    <app-contact-us class="contact-us-wrapper" [showMap]="environment.enableGoogleMaps"></app-contact-us>
</div>

<app-footer class="footer"></app-footer>