<div *ngIf="environment.envType !== ENUMS.HOUSEHUB_SITE"
    class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative text-uppercase">
                sitemap
            </div>
            <span class="dash"></span>
            <div class="content mt-5 realtor-description">

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/about-us']">About</a></div>
                    <div class="ml-5">*&nbsp;<a [routerLink]="['/about-us']">About Us</a></div>
                    <div class="ml-5">*&nbsp;<a [routerLink]="['/testimonials']">Testimonials</a></div>
                </div>

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/search-property']">Properties</a></div>
                    <div class="ml-5">*&nbsp;<a [routerLink]="['/search-property']">Search</a></div>
                    <div class="ml-5">*&nbsp;<a [routerLink]="['/search-property']"
                            [queryParams]="{ status: 'Featured' }">Featured</a></div>
                </div>

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/communities']">Communities</a></div>
                    <div *ngFor="let c of communityData" class="ml-5">*&nbsp;<a
                            [routerLink]="['/community/', c.city.toLowerCase()]">{{ c.city }}</a></div>
                </div>

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/buyers-process']">Buyers</a></div>
                    <div class="ml-5">*&nbsp;<a [routerLink]="['/buyers-process']">Process Roadmap</a></div>
                    <div class="ml-5">*&nbsp;<a [routerLink]="['/search-property']">Search Property</a></div>
                </div>

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/sellers-process']">Sellers</a></div>
                </div>

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/property-management']">Property Management</a></div>
                    <div class="ml-5">*&nbsp;<a
                            href="https://universalrealty.appfolio.com/oportal/users/log_in">Homeowner Portal</a></div>
                    <div class="ml-5">*&nbsp;<a href="https://universalrealty.appfolio.com/connect/users/sign_in">Tenant
                            Portal</a></div>
                </div>

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/my-profile']">My Profile</a></div>
                </div>

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/blogs']">Blogs</a></div>
                </div>

            </div>
        </div>
    </div>
</div>

<div *ngIf="environment.envType === ENUMS.HOUSEHUB_SITE"
    class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative text-uppercase">
                sitemap
            </div>
            <span class="dash"></span>
            <div class="content mt-5 realtor-description">

                <div class="d-flex flex-column justify-content-start align-items-start">
                    <div>*&nbsp;<a [routerLink]="['/pricing']">Pricing</a></div>
                </div>

            </div>
        </div>
    </div>
</div>

<app-footer class="footer"></app-footer>