<div class="outter-wrapper d-flex flex-column justify-content-center position-relative terms-of-service">
    <div *ngIf="env.envType === ENUMS.HOUSEHUB_SITE" class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative text-uppercase">
                Terms of Service
            </div>
            <span class="dash"></span>
            <div class="content mt-5">
                <p>
                    These Terms of Service ("Agreement") is entered into by and between entity or person accessing or
                    using House Hub Services ("Client" or “you”) and House Hub LLC, a North Carolina company (“House
                    Hub”). This agreement consists of the terms and conditions set forth below and any Order Forms that
                    reference this Agreement.
                </p>

                <p>
                    House Hub may modify the terms and conditions of this Agreement in accordance with Section 9.4
                    (Amendment / Waivers).
                </p>

                <p>
                    If you accept this agreement, sign an Order Form with House Hub, or use House Hub service, it means
                    you agree to follow all the rules and conditions in this agreement. If you don't agree, please don't
                    use the House Hub service. Both parties agree that this agreement is legally binding upon both
                    parties.
                </p>

                <h1>
                    Definitions
                </h1>
                <h2>
                    The following terms, when used in this Agreement will have the following definitions:
                </h2>
                <p>
                    "Confidential Information": Any information or data that one party shares with the other, marked as
                    secret or important, or that would reasonably be seen as secret given what it is and how it's
                    shared. But, it doesn't include information that: (a) is already known to the receiving party or in
                    the public; (b) the receiving party knew before it was shared; (c) was shared with the receiving
                    party by someone who could legally do so; or (d) the receiving party figured out on their own
                    without using the other party's secret information.
                </p>

                <p>“Client Content”: The content and any other materials provided or accessible to House Hub by the
                    Client through the use of House Hub Services. It does not include the House Hub Services themselves,
                    along with their related materials, templates, codes(source/object, etc), and technology.</p>

                <p>“Source Code”: Those statements in a computer language which, when processed by a compiler, assembler
                    or interpreter, become executable by a computer and includes, without limitation, all comments,
                    notes, flow charts, decision tables, argument lists and other human readable references relating to
                    the operation, purpose, compilation, assembly or interpretation thereof.</p>

                <p>“Object Code”: The compiled version of the Source Code.</p>

                <p>“House Hub Services”: The website development, maintenance, hosting and other related services for
                    the purpose of establishing and/or enhancing Client’s online presence and/or Client’s real estate
                    business.</p>

                <p>“House Hub Template”: The template Terms of Service and Privacy Policy forms provided by House Hub to
                    the Client.</p>

                <p>“Web Template”: The web site template provided by House Hub to the Client.</p>

                <p>“Order Form”: The quote or other similar document that sets forth the specific services and pricing,
                    and that references this Agreement and is executed by both parties.</p>


                <h1>House Hub Services</h1>

                <h2>Provision of Services: House Hub will provide its services to the Client according to this agreement
                    and any specific Order Form. The Client is given permission to use these services to run and show
                    the website mentioned in the Order Form. But the services need the Client to work with House Hub to
                    create the website. If the Client doesn't cooperate as needed, it might take longer to launch the
                    website causing delay. </h2>

                <h2>Business Hours / Rush Work: House Hub representatives are available during normal business hours
                    which are 9 a.m. to 5 p.m. EST, Monday through Friday. Any email or telephone correspondence
                    received after normal business hours will be processed the following business day. Client shall pay
                    an additional surcharge for any services requiring work to be performed outside of normal business
                    hours by reason of a rush deadline requested by Client or as a result of Client’s failure to meet
                    scheduled times for delivery and/or review and approval of information, content and materials. The
                    surcharge for rush work shall be the standard hourly rate plus twenty-five percent (25%).</h2>

                <h2>Client Limitations (License Restrictions): </h2>

                <h3>Client will not reverse engineer, decompile, modify, create work derived from the underlying House
                    Hub Services. Client will not transfer, distribute, resell, lease, license, or assign House Hub
                    Services.</h3>

                <h3>Client must not use the House Hub Services to break any laws, whether they're local, state,
                    national, or international; Client shouldn't pretend to be someone else or falsify about their
                    connection to someone or something; solicit personal information from anyone under 18 years old;
                    and, can't use the Services to support or encourage illegal activities or to teach others how to do
                    illegal activities.</h3>

                <h3><b>House Hub has exclusive rights and ownership of all Source Codes/Web Templates provided, written,
                        generated, or modified as a part of House Hub Services. </b></h3>

                <h3>Client will not use House Hub Services outside the scope permitted in the applicable Order Form.
                </h3>

                <h2>Client Responsibilities: Client is solely responsible for the accuracy, quality, integrity and
                    legality of Client Content and Client website(s).</h2>
                <h2>House Hub Templates are Not Substitute for Legal Advice: The Terms of Services and any other House
                    Hub documents/templates are provided for Client’s private use and do not constitute legal advice.
                </h2>
                <h2>Delays to Subscription Start Date: Client shall be responsible for the recurring monthly
                    subscription fees after Subscription Start Date, as depicted in the corresponding Order Form. The
                    Subscription Start Date may only be postponed if there are website bugs that prevent the site from
                    functioning materially as listed in the agreement. </h2>


                <h2>Launch of Website</h2>
                <h3>Content by Client: Client shall provide all requested website content using method designated by
                    House Hub within fourteen (14) days from the effective date. Launch date shall be pushed back by any
                    amount of days delayed from the aforementioned time basis. All written content submitted by Client
                    for
                    use in the Client Website must be typewritten, proofread and delivered to House Hub in the body of
                    an
                    email message or as a Microsoft Word or PDF electronic document or plain text electronic document.
                    House
                    Hub will not make any attempt to proof read or correct any contextual, grammatical or typographical
                    errors in the written content submitted by Client. It is Client’s sole responsibility to check the
                    accuracy of the written content and correct any errors prior to submission for final publication.
                </h3>
                <h3>Process of Revision: House Hub shall deliver staging link to Client in order to review initial
                    draft of the website no later than fourteen (14) days from the day all necessary content is received
                    by
                    House Hub. Then Client shall request any further revisions to the website within seven (7) days
                    using
                    method designated by House Hub. House Hub then have seven (7) days to make revisions. House Hub
                    shall
                    provide up to two (2) rounds of revisions given Client’s reasonable approval of all revisions in
                    each
                    round. Upon Client confirmation of each round of change/revision, House Hub would not proceed with
                    any
                    additional revision work. Any revision requests shall be part of the rounds of revisions no matter
                    how
                    small the revision is.</h3>
                <h3>Final approval: Client shall give final approval within seven (7) days of receiving the staging
                    link of the revised website. With Client providing all necessary information in a timely manner
                    aforementioned, House Hub shall perform best efforts to launch website no later than four (4) weeks
                    from
                    the effective date of this Agreement.</h3>
                <h3>Revision Scope: Revisions shall only include changes within the existing template such as HTML
                    and CSS. Revision does not include any custom coding or changes to existing template features, which
                    shall be subject to House Hub’s approval and standard hourly rate. Web design is driven highly by
                    Client’s direction. If the original template is modified in any way, any final deliverable website
                    shall
                    be the responsibility of the Client.</h3>

                <h1>Fees</h1>
                <h2>Client shall pay House Hub fees set in the Order Form. This includes, but not limited to, setup fee
                    and subscription fee, and potentially other fees based on services requested by the Client. Client
                    agree
                    to pay these fees on the date specified in the Order Form independently of whether or not the
                    website
                    has been launched at that time. House Hub reserve the right to change its fees at is sole
                    discretion. If
                    fee does change, House Hub shall provide Client with written notice and new fee shall take effect
                    upon
                    next renewal.</h2>
                <h2>Fees are quoted and payable in United States dollars. Payment obligations are non-cancelable,
                    non-pro-ratable for partial months, and non-refundable. Unpaid amounts are subject to a late fee of
                    $80
                    if unpaid for more than fourteen (14) days, $200 if unpaid for more than thirty (30) days.
                    Additional
                    expenses associated with unpaid amount shall be Client’s responsibility, that includes but not
                    limited
                    to collection.</h2>
                <h2>Payment shall be made by the Client in a timely manner and to accounts designated by House Hub.</h2>

                <h1>Rights and Confidentiality</h1>
                <h2>House Hub Ownership Rights: Between Client and House Hub, House Hub exclusively owns all right,
                    title, and interest in and to the House Hub Services and House Hub Confidential Information.</h2>
                <h2>House Hub Mark: House Hub grants Client a non-transferable, non-sublicensable, non-exclusive
                    license during the term of this Agreement to display the trade names, trademarks, service marks,
                    logos,
                    domain names of House Hub.</h2>
                <h2>Client Content: As between Client and House Hub, Client grants House Hub a non-exclusive, worldwide
                    license to copy, distribute and use Client Content only in connection with providing the House Hub
                    Services.</h2>
                <h2>Confidentiality: Both parties shall only use each other's confidential information how this
                    agreement depicts. Each party won't disclose to any other parties confidential information unless
                    they
                    get written permission from the other party or unless this agreement approves such disclosure.</h2>
                <h2>Aggregated Information: House Hub shall have the right to aggregate, collect, and analyze data and
                    other information related to the House Hub Services and shall be free to use such data to develop
                    and
                    improve House Hub Services and other offerings, during and after the term of this Agreement.</h2>

                <h1>Warranties and Disclaimers</h1>
                <h2>House Hub Warranties: House Hub warrants that it will use reasonably efforts to maintain House Hub
                    Services in a manner to minimize errors and interruptions in a professional manner.</h2>
                <h2>Client Warranties: Client warrants that it has the necessary rights, licenses, consents,
                    permissions, waivers and releases to use, make available and distribute the Client Content in
                    connection
                    with the House Hub Services. In addition, Client shall comply with all applicable laws and
                    regulations
                    on online conduct, data privacy, illegal activities, and intellectual property.</h2>
                <h2>Disclaimer: House Hub does not warrant its service will be error-free. House Hub is not responsible
                    or liable for any products or services not provided by House Hub, including but not limited to third
                    party products(e.g. services for sending contact information, MLS services for search properties),
                    and
                    does not guarantee the availability of any integration with such products or service.</h2>

                <h1>Indemnification</h1>
                <h2>Client agrees to protect House Hub from any claims, demands, lawsuits, or legal actions made by
                    third
                    parties related to the Client's content, violation of the agreement, or use of House Hub templates.
                    The
                    Client will also compensate House Hub for any damages awarded or settlements approved against House
                    Hub
                    in connection with such claims.</h2>


                <h1>Limitation of Liability</h1>
                <p>In no event, regardless of the legal basis including but not limited to tort, contract, or any other
                    legal theory, shall House Hub be held liable for any special, incidental, indirect, consequential,
                    or
                    punitive damages, including damages for loss of goodwill, lost profits, lost sales or business, work
                    stoppage, computer failure or malfunction, lost content or data, or for any other damages or losses,
                    regardless of whether a representative of House Hub was advised, knew, or should have known of the
                    possibility of such damages. The aggregate liability shall not exceed the total amounts paid by the
                    Client under the relevant Order Form within the twelve (12) months preceding the incident or claim.
                </p>

                <h1>Termination</h1>
                <h2>Description: The duration of this Agreement shall commence on the Effective Date specified in the
                    initial Order Form and shall persist until terminated as outlined below. The subscription period
                    shall
                    commence upon the earlier of (a) the Subscription Start Date as indicated in the Order Form, or (b)
                    the
                    launch date of the website, and shall continue until the Subscription End Date specified in the
                    Order
                    Form. Unless otherwise stipulated in the said Order Form, the duration of said Order Form shall
                    automatically extend for subsequent renewal periods equivalent to the duration of the initial term
                    of
                    said Order Form, unless one party furnishes the other party with written notice of non-renewal at
                    least
                    thirty (30) days prior to the conclusion of the ongoing term.</h2>
                <h2>Termination: Either party may terminate this Agreement by providing written notice to the other
                    party if there are no Order Forms currently in effect. Additionally, each party reserves the right
                    to
                    terminate this Agreement upon written notice in the following circumstances: The other party
                    breaches a
                    material provision of this Agreement and fails to rectify such breach within thirty (30) days after
                    receiving written notice thereof; or the other party undergoes liquidation, initiates dissolution
                    proceedings, or assigns substantially all its assets for the benefit of creditors, or becomes
                    subject to
                    bankruptcy or similar proceedings that remain unresolved for more than sixty (60) days under
                    applicable
                    law.

                    House Hub reserves the right to immediately suspend any House Hub Services upon notice if: House Hub
                    reasonably determines that providing any of the House Hub Services is prohibited by applicable law,
                    or
                    has become impractical or unfeasible for any legal or regulatory reason; or Client violates, or
                    gives
                    reason to believe it has violated, the License Restrictions.</h2>
                <h2>Early Termination by Client: Client can cancel this Order Form before the Subscription End Date by
                    giving thirty (30) days written notice to House Hub. Client needs to pay 100% of the remaining fees
                    for
                    the full Subscription Term, including any unpaid fees for setup, marketing, add-on services, and so
                    on.
                    Any fees already paid or still owing at the time of cancellation won't be refunded.</h2>
                <h2>Suspension: House Hub reserve the right to suspend House Hub Service and hosting of Client website
                    at any time if it believes in good faith that Client’s use of Service may be in violation of the
                    Agreement or if Client has not paid in full any invoice within fourteen (14) days after invoice was
                    due.
                    House Hub shall not be liable or responsible for any damages to Client as a result from the
                    suspension
                    or ending of Client’s Service. All invoices/balances need to be paid in full before Service/Account
                    is
                    restored. Suspension of House Hub Service shall not release Client from any unpaid fees.</h2>
                <h2>Survival: Upon termination of this Agreement, all terms/rights/obligations in this Agreement stops
                    except for certain items that should still apply. These include rules on License Restrictions,
                    proprietary rights and confidentiality, disclaimers, indemnification, limitations of liability,
                    termination and the general provisions. These rules will still be in place even after the Agreement
                    ends.</h2>

                <h1>General</h1>
                <h2>Export Control: Each party shall adhere to the export laws and regulations of the United States,
                    European Union, and any other pertinent jurisdictions in the provision and utilization of the House
                    Hub
                    Service.</h2>
                <h2>Publicity: Client gives permission that House Hub could refer to Client’s names, logos, and
                    trademarks in House Hub’s advertising, marketing materials, website, and promotion of its products
                    and
                    services. Client agrees that House Hub may use and display the graphics and other web design
                    elements of
                    Client’s website as examples of House Hub website design and development work. Client gives
                    permission
                    that House Hub could include link to House Hub’s website in the footer of Client website and freely
                    showcase any product provided to Client on House Hub’s social media channels and/or website.</h2>
                <h2>Assignment/Delegation: Neither party may assign/transfer this Agreement, in part or whole, without
                    the other party’s prior written consent. However, either party retains the right to transfer this
                    Agreement to a successor who acquires all or substantially all of its assets or business pertaining
                    to
                    this Agreement without requiring consent. If either party attempt to assign, delegate, or transfer
                    this
                    Agreement that violates Assignment/Delegation in the Agreement, such attempt to assign, delegate, or
                    transfer will be null and void. If Assignment/Delegation is valid subject to the foregoing, this
                    Agreement will be valid for the parties and whoever takes over for them in the future.</h2>
                <h2>Amendment / Waivers: House Hub reserves the right to modify this Agreement at anytime and for any
                    reason. Regarding each Order Form, any changes to this Agreement will take effect once they're
                    posted.
                    It is Client’s responsibility to review this Agreement for any changes or modification. Client may
                    provide written note to House Hub to terminate this Agreement if does not agree to the modified
                    Agreement. No waiver by either party of any breach or default shall be deemed to be a waiver of any
                    preceding or subsequent breach or default. Any such wavier is only to the specific provision and
                    will
                    not apply to any repeated or continuing mistake or violation. If one party doesn't act on something
                    in
                    this Agreement right away, it doesn't mean they're giving up the right to enforce it later, or any
                    other
                    parts of the Agreement.</h2>
                <h2>Relationship: Nothing written here indicate the parties are forming a partnership, agency,
                    employment, or joint venture. This Agreement doesn't show the parties plan to create any of those
                    relationships. Neither party will have the right to obligate the other in any manner. Nothing
                    written
                    here gives the right of any kind to any third parties.</h2>
                <h2>Unenforceability: If a court decides that any part of this Agreement isn't valid, illegal, or can't
                    be enforced, they'll try to enforce it as closely as they can to what the parties meant. The rest of
                    the
                    Agreement will still be valid and enforceable, binding the parties as laid out in its terms.</h2>
                <h2>Governing Law: This Agreement will be only be governed by the laws of the State of North Carolina,
                    USA, exclusive of its rules governing choice of law and conflict of laws.</h2>
                <h2>Notices: Any notice given to House Hub will be given via <a
                        href="mailto:househubnc@gmail.com">email</a>. Notice to
                    Client must be sent to the email or address in the applicable Order Form.</h2>
                <h2>Entire Agreement: This Agreement comprises the entire agreement between Client and House Hub with
                    respect to the Service provided by House Hub.</h2>
                <h2>House Hub uses third party services to the best of its ability.</h2>
                <h2>Neither party shall be considered to have breached this Agreement if they are unable to fulfill
                    their obligations due to circumstances beyond their control, such as natural disasters, act of God,
                    cyber attack (e.g., denial of service attacks, web defacement attack, cross-site scripting/XSS,
                    CSRF),
                    labor disputes, acts of terrorism, war(officially declared or not), hosting outage (e.g. web service
                    provider has outage), or changes in laws or regulations.</h2>


            </div>
        </div>
    </div>

</div>

<app-footer class="footer"></app-footer>