<div class="outter-wrapper d-flex flex-column justify-content-center position-relative privacy-policy">
    <div *ngIf="env.envType !== ENUMS.HOUSEHUB_SITE" class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <h1 class="title relative text-uppercase">
                Privacy Policy For Users Interacting With House Hub Clients
            </h1>
            <span class="dash"></span>
            <div class="content mt-5 realtor-description">
                <p>
                    House Hub LLC (“House Hub”, “we”, “us”, or “our”) provides technologies, products and services
                    (“Services”) used by our clients whose branding is displayed on the website with which you are
                    interacting with (“Client”).
                </p>

                <p>
                    If you are a user of our Client’s website(“Website”, “Site”), this Privacy Policy describes to you
                    regarding the retrieval, use, and disclosure of your personal information on your usage of the Site
                    and our Services to our Client. In this case, House Hub solely act as a service provider and data
                    processor, and only uses the personal information collected at the direction of our Client. This
                    Privacy Policy does not apply to any websites, services or applications that do not link to this
                    policy (including other products/services provided by the Client), nor does this Privacy Policy
                    apply to the Client’s subsequent use of the collected information through the Site or Service. You
                    should contact the Client to learn about how they use your personal information.
                </p>

                <p>
                    House Hub collects information from you when you access or use the Site, including but not limited
                    to:
                </p>
                <h2>
                    Information you provide to us:
                </h2>
                <ul>
                    <li *ngFor="let i of infoProvideToUs">
                        {{ i }}
                    </li>
                </ul>
                <h2>
                    Information we obtain from third parties:
                </h2>
                <ul>
                    <li *ngFor="let i of infoFromThirdParty">
                        {{ i }}
                    </li>
                </ul>
                <h2>
                    Automatic data collection:
                </h2>
                <ul>
                    <li *ngFor="let i of automaticDataCollection">
                        {{ i }}
                    </li>
                </ul>
                <h2>
                    How We Use Your Personal Information:
                </h2>
                <ul>
                    <li *ngFor="let i of howWeUsePersonalInfo">
                        {{ i }}
                    </li>
                </ul>
                <h2>
                    How We Share Your Personal Information:
                </h2>
                <ul>
                    <li *ngFor="let i of howWeShareYourInfo">
                        {{ i }}
                    </li>
                </ul>
                <h2>
                    Privacy Choices:
                </h2>
                <p>
                    You have the option to decline receiving marketing-related texts/emails by adhering to the opt-out
                    or unsubscribe guidelines provided at the bottom of the emails we send. Even if you opt out, you
                    will still receive service-related and other non-marketing emails until you discontinue using our
                    services tied to those updates. To opt out of marketing-related communications via text message, you
                    can reach out to us directly or reply with "STOP".
                </p>
                <p>
                    Blocking cookies in your browser: Most browsers allow you to remove or reject cookies, including
                    cookies
                    used for interest-based advertising. Follow the instructions in your browser settings if you would
                    like to do pursue this. Some browsers accept cookies by default until you change it in your
                    settings. For
                    more information about cookies, including how to view the cookies that have been set on your device
                    and
                    how to manage and delete them, please visit www.allaboutcookies.org.
                </p>
                <p>
                    Google Analytics: We use Google Analytics to help us better understand how people engage with the
                    Services by collecting information and creating reports about how users use our Services. For more
                    information on Google Analytics, click <a target="_blank"
                        href="https://marketingplatform.google.com/about/analytics/">here</a>. For more information
                    about Google’s privacy practices, click <a target="_blank"
                        href="https://www.google.com/policies/privacy/partners/">here</a>. You can opt out of Google
                    Analytics by downloading and installing the browser plug-in <a target="_blank"
                        href="https://tools.google.com/dlpage/gaoptout">here</a>.
                </p>
                <h2>
                    Third Party Services:
                </h2>
                <p>
                    Our website and services may include links to websites and other online services operated by third
                    parties. Additionally, our content might be incorporated into web pages or other online services
                    that are not under our control. Please note that these links and integrations do not imply
                    endorsement or affiliation with any third party. We do not oversee websites or online services
                    operated by third parties, and we are not responsible for their activities.
                </p>

                <h2>
                    Information Security:
                </h2>
                <p>
                    We have implemented multiple technical and physical measures to safeguard the personal information
                    we collect. Nonetheless, it's essential to recognize that no security measures are entirely
                    infallible, and we cannot provide an absolute guarantee regarding the security of personal
                    information.
                </p>

                <h2>
                    Children Privacy:
                </h2>
                <p>
                    Our services are not meant for individuals under the age of 18. If we discover that we have gathered
                    personal information from a child under 18 without the necessary consent from their parent or
                    guardian, as mandated by law, we will promptly remove it from our records.
                </p>

                <h2>
                    Changes to This Privacy Policy:
                </h2>
                <p>
                    We retain the right to modify this Privacy Policy at any given time without prior notice. Should
                    substantial alterations be made to this policy, we will inform you by updating the Privacy Policy
                    and posting it on this website.
                </p>


                <h2>
                    Contact Us:
                </h2>
                <p>
                    You can reach us by email at <a href="mailtp: househubnc@gmail.com">House Hub</a>
                </p>

            </div>
        </div>
    </div>


    <div *ngIf="env.envType === ENUMS.HOUSEHUB_SITE" class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <h1 class="title relative text-uppercase">
                Privacy Policy
            </h1>
            <span class="dash"></span>
            <div class="content mt-5 realtor-description">
                <p>
                    House Hub provides website development, hosting, online marketing and other related services to our
                    Clients. This Privacy Policy describes how we handle the personal information that we collect from
                    our Clients. This Privacy Policy does not apply to personal information about our Client’s Clients
                    or end customers.
                </p>

                <p>
                    Our services are designed for business purposes exclusively and are not intended for personal use.
                    Under this Privacy Policy, any personal information covered pertains to individuals acting in their
                    capacity as business representatives, rather than in a personal capacity.
                </p>

                <p>
                    <b>Notice and Disclosure:</b>
                </p>
                <h2>
                    Personal Information We May Collect:
                </h2>
                <p>
                    Business contact information, such as your first and last name, phone number, and email address.
                </p>

                <p>Online activity data, such as the pages or screens you visited, the amount of time you spent on each
                    page or screen, your browsing history, the paths you took between pages or screens, details about
                    your interactions on a page or screen, the times you accessed the information, how long you accessed
                    it for, and whether you opened our marketing emails or clicked on any links within them.</p>

                <p>Cookies, are text files stored on your device to uniquely identify your browser or to store settings
                    or information in the browser to help you navigate between pages more effectively, remember your
                    preferences, enable functionality, help us understand user activity and patterns, and facilitate
                    online advertising.</p>

                <p>Local storage technologies, that provide cookie-equivalent functionality but can store larger amounts
                    of data.</p>


                <h2>
                    How We Use Your Personal Information:
                </h2>
                <p>To communicate with you about our services and administrative purpose.</p>

                <p>To operate, research/analyze products and services, and improve our Services and our business.</p>

                <p>For marketing and advertising, we and our advertising partners may collect and use your personal
                    information for marketing and advertising purposes. We may from time-to-time send you direct
                    marketing communications via email, phone and text message as permitted by law, including, but not
                    limited to, and notifying you of special promotions and offers. You may opt out of our marketing
                    communications as described in the “Opt out of marketing communications” section below.</p>

                <p>To comply with law, we believe it is necessary or appropriate to comply with applicable laws, lawful
                    requests, and legal process, such as to respond to subpoenas or requests from government
                    authorities.</p>

                <h2>
                    Privacy Choices:
                </h2>

                <p>You have the option to decline receiving marketing-related texts/emails by adhering to the opt-out or
                    unsubscribe guidelines provided at the bottom of the emails we send. Even if you opt out, you will
                    still receive service-related and other non-marketing emails until you discontinue using our
                    services tied to those updates. To opt out of marketing-related communications via text message, you
                    can reach out to us directly or reply with "STOP".</p>

                <p>Blocking cookies in your browser. Most browsers let you remove or reject cookies, including cookies
                    used for interest-based advertising. Follow the instructions in your browser settings if you would
                    like to do pursue this. Many browsers accept cookies by default until you change your settings. For
                    more information about cookies, including how to see what cookies have been set on your device and
                    how to manage and delete them, visit allaboutcookies.org.</p>

                <p>Google Analytics. We use Google Analytics to help us better understand how people engage with the
                    Services by collecting information and creating reports about how users use our Services. For more
                    information on Google Analytics, click <a
                        href="https://marketingplatform.google.com/about/analytics/" target="_blank">here</a>. For more
                    information about Google’s privacy practices, click <a
                        href="https://policies.google.com/technologies/partner-sites" target="_blank">here</a>. You can
                    opt out of Google Analytics by downloading and installing the browser plug-in available at:
                    https://tools.google.com/dlpage/gaoptout.</p>

                <h2>
                    Third Party Services
                </h2>
                <p>
                    Our website and services may include links to websites and other online services operated by third
                    parties. Additionally, our content might be incorporated into web pages or other online services
                    that are not under our control. Please note that these links and integrations do not imply
                    endorsement or affiliation with any third party. We do not oversee websites or online services
                    operated by third parties, and we are not accountable for their activities.</p>

                <h2>
                    Information Security
                </h2>
                <p>We have implemented multiple technical and physical measures to safeguard the personal information we
                    collect. Nonetheless, it's essential to recognize that no security measures are entirely infallible,
                    and we cannot provide an absolute guarantee regarding the security of personal information.</p>
                <h2>
                    Children Privacy:
                </h2>
                <p>
                    Our services are not meant for individuals under the age of 18. If we discover that we have gathered
                    personal information from a child under 18 without the necessary consent from their parent or
                    guardian, as mandated by law, we will promptly remove it from our records.
                </p>

                <h2>
                    Changes to This Privacy Policy:
                </h2>
                <p>
                    We retain the right to modify this Privacy Policy at any given time without prior notice. Should
                    substantial alterations be made to this policy, we will inform you by updating the Privacy Policy
                    and posting it on this website.
                </p>


                <h2>
                    Contact Us:
                </h2>
                <p>
                    If you have any questions or comments about our privacy statement or practices, please contact us
                    via <a href="mailto:househubnc@gmail.com">email</a> with the words “PRIVACY POLICY” in the subject
                    of email.
                </p>

            </div>
        </div>
    </div>

</div>

<app-footer class="footer"></app-footer>