import { CommonModule, NgOptimizedImage } from '@angular/common';
import { Component, ViewChild, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faBed, } from '@fortawesome/free-solid-svg-icons';
import { environment } from '../../env/environment';
import { PropertyCardComponent } from '../property-card/property-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { AppServiceService } from '../services/app-service.service';

@Component({
  selector: 'app-featured-properties',
  standalone: true,
  imports: [
    CommonModule,
    SlickCarouselModule,
    HttpClientModule,
    FontAwesomeModule,
    PropertyCardComponent,
    TranslateModule,
  ],
  templateUrl: './featured-properties.component.html',
  styleUrl: './featured-properties.component.scss'
})
export class FeaturedPropertiesComponent {

  @ViewChild('slickCarousel') slickCarousel: SlickCarouselModule = new SlickCarouselModule;

  slides: { img: string }[] = [];
  slideConfig = {
    'slidesToShow': 2,
    'slidesToScroll': 2,
    'dots': false,
    'infinite': false,
    'autoplay': false,
    // responsive: [
    //   {
    //     'breakpoint': 1024,
    //     'settings': {
    //       'slidesToShow': 1,
    //       'slidesToScroll': 1,
    //       'infinite': true,
    //       'dots': false
    //     }
    //   }, {
    //     'breakpoint': 991,
    //     'settings': {
    //       'slidesToShow': 1,
    //       'slidesToScroll': 1,
    //       'infinite': true,
    //       'dots': false
    //     }
    //   }, {
    //     'breakpoint': 480,
    //     'settings': {
    //       'slidesToShow': 1,
    //       'slidesToScroll': 1,
    //       'infinite': true,
    //       'dots': false
    //     }
    //   }
    // ],
  };

  url = '';
  propertyDetails: any = null;
  streetAddress = [];

  private httpHeaders = new HttpHeaders()
    .set('Accept', '*.*')
  // .set('Authorization', `Bearer ${environment.mlsBearToken}`);

  constructor(
    private httpClient: HttpClient,
    private activatedRoute: ActivatedRoute,
    public appService: AppServiceService,
  ) {

  }

  ngOnInit() {
    // Could add attribute BuyerOfficeName as another API call.
    let body = { url: 'and[0][ListOfficeName][eq]=universal realty&and[1][YearBuilt][gt]=1990&and[2][ListOfficeName][eq]=Universal Realty&and[3][MlsStatus][in]=Active,Pending&limit=10&offset=0&sortBy[0]=BridgeModificationTimestamp' };
    let api = 'https://ev7muqdq94.execute-api.us-east-1.amazonaws.com/prod/properties';

    try {
      this.httpClient.post(api, body, { headers: this.httpHeaders, observe: 'response' }).subscribe(
        (data: any) => {
          // console.log(data);
          this.propertyDetails = null;
          if (data && data.body && data.body.bundle && data.body.bundle.length > 0) {
            this.propertyDetails = data.body.bundle;
            // console.log(this.propertyDetails);

            // if (this.propertyDetails && this.propertyDetails.Media && this.propertyDetails.Media.length > 0) {

            //   this.streetAddress = this.propertyDetails.UnparsedAddress.split(',');

            // }
          }
        },
        (error) => {
          console.log(error);
        }
      );
    } catch (e) {
      console.log(e);
    }

    if (this.appService.isMobile) {
      this.slideConfig = {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'dots': false,
        'infinite': true,
        'autoplay': false,
      }
    }
  }


  // breakpoint(e: any) {
  //   console.debug('breakpoint');
  // }

}
