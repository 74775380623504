import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { environment } from '../../env/environment';
import { FormBuilder, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatStepperModule } from '@angular/material/stepper';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';
import { AuthService } from '../services/auth.service';
import { ENUMS } from '../../models/constants';

@Component({
  selector: 'app-testimonial',
  standalone: true,
  imports: [
    CommonModule,
    ContactUsComponent,
    MatStepperModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    FooterComponent,
  ],
  templateUrl: './testimonial.component.html',
  styleUrl: './testimonial.component.scss'
})
export class TestimonialComponent implements OnInit {

  environment = environment;

  testimonials: any;

  constructor(
    private appService: AppServiceService,
    private authService: AuthService,
  ) {

    this.authService.checkRoute();

    if (environment.envType === ENUMS.UNIVERSAL_REALTY_SITE) {
      this.testimonials = [
        {
          author: 'Jun',
          message: "I highly recommend her. She helped us to convince the seller and manage to have our offer accepted at a reasonable price in a very competitive bidding war.  She is professional and knowledgeable in the real estate business, and  also very personal and accommodates our needs as clients.",
        },
        {
          author: 'Charles',
          message: "Whenever we have any question, she always replies us very quickly. Overall she is very professional, responsible and meticulous.  We are very happy to choose her as our agent.",
        },
        {
          author: 'Michael',
          message: "Top notch realtor in the triangle. Caring, professional, knowledgeable are some of the words I would describe Michelle. Highly recommend for anyone buying or selling their house.",
        },
        {
          author: 'Dave',
          message: "She is readily available and her communication skills are off the charts. I can give no higher recommendation!",
        },
        {
          author: 'Anthony',
          message: "Her knowledge of the area, and property as well as general knowledge about the loan process, building codes etc were all instrumental to assisting us to finding the right home to fit for our needs.",
        },
        {
          author: 'Dave',
          message: "She is readily available and her communication skills are off the charts. I can give no higher recommendation!",
        },
        {
          author: 'Xiao',
          message: "No matter when we had a question related to the house hunting or other minor issues (best trail around research triangle area :)) , she always responded it on time. As a realtor agent, she is not only knowledgeable and professional but also kind and thoughtful. We are so lucky to have her as our agent.  If you want to buy/sell a house in research triangle area, we highly recommend her and her group to help you.",
        },
        {
          author: 'H Z',
          message: "From signup the purchase contract to pre-construction meeting, pre-drywall meeting, pre-settlement orientation, final walk-through, and closing, she gave me a lot of help and valuable advices at every step. I highly recommend her service.",
        },
      ]
    }

    if (environment.envType === ENUMS.PRO_REALTY_SITE) {
      this.testimonials = [
        {
          author: 'Nick',
          message: "Desong and Bin were a fantastic help finding and buying our first house. They are very knowledgeable about the process, diligent, and were patient with us as first time buyers. We love the house and highly recommend them for anyone looking to buy a home in the RTP area.",
        },
        {
          author: 'John',
          message: 'As brand new residents of the state of North Carolina, and the triangle area, Desong was quite instrumental in assisting with our rental home and finally in the purchase of our new permanent home. Desong proved to be just the real estate professional we needed to help us navigate the transition from renting, to researching homes in the surrounding towns and cities, to formulating a strategic approach to helping us achieve our goal of home ownership. I really appreciated how involved Desong was throughout the entire process, from our initial search, all the way to closing, and even after, he was on the ball. My wife and I often remark how fortunate we were to arrive in a new state and be blessed with a Real Estate professional as knowledgeable, helpful, trustworthy, and a joy to work with as Desong.',
        },
        {
          author: 'Jerry',
          message: "My wife and I had the pleasure to work with Desong and Bin during our purchase of our primary home. We have worked with many Realtors over the years. Desong and Bin really stood out as one of the best we've worked with. We felt that we were incredible partners throughout our home-buying journey. From the first conversation to closing, they were knowledgeable, responsive, and always had our best interests in mind. Their expertise made the process smooth and stress-free, and we couldn’t be happier with our new home.",
        },
        {
          author: 'W.Naxin',
          message: "Desong and Bin are top-notch realtors in the Triangle! They are caring, professional, and extremely knowledgeable, making the entire process seamless. Whether you're buying or selling, their expertise and dedication shines through every step of the way. We highly recommend them to anyone looking for a trusted partner in real estate!",
        },
        {
          author: 'Baohong',
          message: "Working with Desong and Bin on the purchase of my investment property was an absolute pleasure—they truly exceeded all my expectations. I’ve worked with many Realtors over the years, but these two really stand out as some of the best. They always kept my best interests front and center. Their deep understanding of the market made everything feel easy to comprehand. Thanks to their guidance, I found the perfect property, and I couldn’t be happier. If you’re thinking about investing in real estate, I can’t recommend them enough!",
        },
        {
          author: 'Mohamed',
          message: "We rented with Bin and Desong for many years and were always happy with their service, so when we decided to buy our first home, it was an easy choice to go with them again. As first-time buyers, we had a lot of unknowns, but Bin and Desong guided us from start to finish. They went above and beyond, making the whole process smooth and even helping us with things we hadn’t considered. We couldn’t be happier with the experience and are grateful for their exceptional support!",
        }
      ]
    }
  }

  ngOnInit(): void {
    this.appService.scrollToTop();
  }

}
