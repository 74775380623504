import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-privacy-policy',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
  ],
  templateUrl: './privacy-policy.component.html',
  styleUrl: './privacy-policy.component.scss'
})
export class PrivacyPolicyComponent implements OnInit {

  env = environment;
  ENUMS = ENUMS;

  infoProvideToUs = [
    'Contact information that you provide.',
    'Marketing information such as selection of preferences.',
    'Other information that is not listed here, but in accordance with this Privacy Policy or otherwise disclosed at the time of collection.',
  ]

  infoFromThirdParty = [
    'Social media information: For Client that maintain social media platforms, such as Linkedin, Instagram, Facebook, and other third-party platforms, when you visit or interact with these pages on those platforms, those platform providers have their own privacy policies that will apply to your interactions. You or the platforms may provide us (on behalf of the Client) with information through the platform, and we will treat those information in accordance with this Privacy Policy.',
    'We may obtain your personal information from other third parties, such as third party applications or analytics services, publicly available sources, and Client’s marketing partners.',
  ]

  automaticDataCollection = [
    'As we provide Client’s advertising service, we and our service providers may automatically log information about you, your computer or mobile device/IP addresses, and your interaction with our Service, and other online services, including but not limited to device data, online activity data.',
    'We may use cookies, which are text files stored on your device to uniquely identify your browser or to store settings or information in the browser to help you navigate between pages more effectively, remember your preferences, enable functionality, help us understand user activity and patterns, and facilitate online advertising.',
    'We may use local storage technologies, that provide cookie-equivalent functionality but can store larger amounts of data.',
  ]

  howWeUsePersonalInfo = [
    'To communicate with you about our services and administrative purpose.',
    'To operate, research/analyze products and services, and improve our Services and our business.',
    'For marketing and advertising, we and our advertising partners may collect and use your personal information for marketing and advertising purposes. If you\'ve shared your contact details with us and/or our Client, we may occasionally send you direct marketing communications on behalf of our Client, as permitted by law. These communications may include phone calls, emails, and text messages. If you wish to opt out of receiving marketing communications, please get in touch with our Client directly.',
    'To comply with law: We believe it is necessary or appropriate to comply with applicable laws, lawful requests, and legal process, such as to respond to subpoenas or requests from government authorities.',
  ]

  howWeShareYourInfo = [
    'We don\'t \"sell\" any personal information, as defined by applicable laws. However, we may share your personal information as outlined below and in other sections of this Privacy Policy.',
    'To our Client, including to facilitate your interactions with our Client, and to service providers of our Client, as directed by our Client.',
    'Companies and individuals who render services on our behalf or aid in the operation of our services or business.',
    'We might share your personal information with professional advisors and vendors, such as lawyers, bankers, auditors, service providers, and insurers, when essential for the professional services they provide to us.',
    'We may share your personal information for the compliance, fraud prevention and safety purposes described above.',
    'We might sell, transfer, or otherwise distribute some or all of our business or assets, which could include your personal information, as part of a business transaction (or potential transaction) like a corporate divestiture, merger, consolidation, acquisition, reorganization, asset sale, or in the event of bankruptcy or dissolution. If such a situation arises, we\'ll make reasonable efforts to ensure that the recipient abides by this Privacy Policy.',
  ]

  constructor(
    private appService: AppServiceService,
    public authService: AuthService,
  ) {
    // this.authService.checkRoute();
  }

  ngOnInit() {
    this.appService.scrollToTop();
  }



}
