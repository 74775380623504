import { Component } from '@angular/core';
import { CommonModule, ViewportScroller } from '@angular/common';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { environment } from '../../env/environment';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';
import { AuthService } from '../services/auth.service';
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { GoogleTagManagerService } from "angular-google-tag-manager";

@Component({
  selector: 'app-hh-pricing',
  standalone: true,
  imports: [
    CommonModule,
    ContactUsComponent,
    FooterComponent,
  ],
  templateUrl: './hh-pricing.component.html',
  styleUrl: './hh-pricing.component.scss'
})
export class HhPricingComponent {

  environment = environment;

  packages = [
    {
      name: 'experience',
      description: 'Best suited for having a professional website online quickly for individual and small teams.',
      price: 800,
      monthly: 149,
      packageDetail: [
        'Choose from over 16 Templates',
        'Customized with your branding',
        'Guided Setup',
        'IDX Integration',
        'Email Support'
      ]
    },
    {
      name: 'semi-custom',
      description: 'Our most popular choice that combines the ease of a website template with the flexibility and customization you need to showcase your unique brand.',
      price: 1500,
      monthly: 149,
      packageDetail: [
        'Everything from Experience plan',
        'Semi-Custom Homepage',
        'Configurable interior pages',
        'Internationalization',
        'Priority Support',
      ]
    },
    {
      name: 'custom studio',
      description: 'Pull out all the stops with a fully bespoke website that match perfectly with your prestigious brand and vision.',
      price: 2500,
      monthly: 149,
      packageDetail: [
        'Everything from Semi-Custom',
        'Premium Website Customization',
        'Custom Interior Pages',
        'Dedicated property page',
        'Dedicated Support',
      ]
    }
  ]

  constructor(
    private appService: AppServiceService,
    private scroller: ViewportScroller,
    public authService: AuthService,
    private analyticsService: GoogleAnalyticsService,
    private gtmService: GoogleTagManagerService,
  ) {
  }

  ngOnInit(): void {
    this.authService.checkRoute();

    this.appService.scrollToTop();

    this.analyticsService.trackEvent("pricing", "", "user-action");

    // Doesn't work
    //https://stackblitz.com/edit/google-tag-manager-with-angular?file=src%2Fapp%2Fapp.component.ts
    // const gtmTag = {
    //   event: "my-btn",
    //   data: "my-custom-event"
    // };
    // this.gtmService.pushTag(gtmTag);
  }

  scroll() {
    this.scroller.scrollToAnchor("target");
  }

}
