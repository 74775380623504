import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AppServiceService } from '../../services/app-service.service';

@Component({
  selector: 'app-jumbotron-video',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './jumbotron-video.component.html',
  styleUrl: './jumbotron-video.component.scss'
})
export class JumbotronVideoComponent implements OnInit {
  @Input() videoUrl!: string;

  mobileImg: any;

  constructor(
    public appService: AppServiceService,
  ) { }

  ngOnInit(): void {
    this.mobileImg = 'assets/images/view-ocean.png';
  }
}
