<div class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative">
                PROPERTY MANAGEMENT
            </div>
            <span class="dash"></span>
            <div class="content mt-5 realtor-description">
                <p>
                    If you live out of the area or live local and simply don’t want the hassle of managing a rental
                    property, we are here to help. Our experienced team of professionals have been managing hundreds of
                    properties in the triangle area, and our fees are reasonable.
                </p>

                <h2>
                    Overview of services we provide:
                </h2>
                <p>
                    · Perform pre-rental inspections<br>

                    · Market property via Triangle MLS, Zillow.com, Truila.com, Realtor.com, and many more online
                    tools<br>

                    · Show property to prospective tenants<br>

                    · Process rental application<br>

                    · Screen applicants – credit, criminal, employment, & rental history<br>

                    · Prepare rental contract and addendums<br>

                    · Collect rent, security and pet deposits<br>

                    · Distribute rent payments<br>

                    · Prepare monthly and annual accounting statements<br>

                    · Handle maintenance requests<br>

                    · Ensure compliance with rental contract<br>

                    · Perform move-in and move-out inspections<br>

                    · Initiate evictions/legal actions if necessary<br>
                </p>
                <h3>

                </h3>
            </div>
        </div>
    </div>

    <span class="dash"></span>

    <app-contact-us class="contact-us-wrapper" [showMap]="environment.enableGoogleMaps"></app-contact-us>


</div>

<app-footer class="footer"></app-footer>