<div *ngIf="env.envType !== enums.HOUSEHUB_SITE">

    <!-- Mobile header  -->
    <header class="static-head w-100 header-wrapper" *ngIf="appService.isMobile">
        <div class="d-flex justify-content-start align-items-end w-100">
            <div class="d-flex header-text">
                <div class="dropdown lang-select">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {{ localeNameOnPage }}
                    </button>
                    <ul class="dropdown-menu">
                        <li *ngIf="localeName !== 'EN'"><a class="dropdown-item" (click)="setLocale('en')">English</a>
                        </li>
                        <li *ngIf="localeName !== '简体'"><a class="dropdown-item" (click)="setLocale('zh-CN')">简体中文</a>
                        </li>
                        <li *ngIf="localeName !== '繁体'"><a class="dropdown-item" (click)="setLocale('zh-HK')">繁体中文</a>
                        </li>
                    </ul>
                </div>
                <a class="header-text-link" [routerLink]="'/'">{{ env?.clientName }}</a><span
                    style="font-size: .8rem"></span>
                <div *ngIf="appService.isMobile">
                    <button mat-button [matMenuTriggerFor]="beforeMenu"><img src="assets/images/hamburger-menu.png"
                            width="30" height="30"
                            [style]="appService.isMobile ? { 'background-color': 'white' } : ''"></button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <a [routerLink]="'/about-us'"><button mat-menu-item>About Us&nbsp;&nbsp;&nbsp;&nbsp;
                            </button></a>
                        <a [routerLink]="'/testimonials'"><button mat-menu-item>Testimonials&nbsp;&nbsp;&nbsp;&nbsp;
                            </button></a>
                        <a [routerLink]="'/search-property'"><button mat-menu-item>Search </button></a>
                        <a [routerLink]="'/search-property'" [queryParams]="{status: 'Featured'}"><button
                                mat-menu-item>Featured
                            </button></a>
                        <a [routerLink]="'/communities'"><button mat-menu-item>Communities </button></a>
                        <a [routerLink]="'/buyers-process'"><button mat-menu-item>Buyers Roadmap </button></a>
                        <a [routerLink]="'/sellers-process'"><button mat-menu-item>Sellers Roadmap </button></a>
                        <a [routerLink]="'/blogs'"><button mat-menu-item>Blogs</button></a>
                        <a *ngIf="env.envType === enums.UNIVERSAL_REALTY_SITE"
                            [routerLink]="'/property-management'"><button mat-menu-item>Property Management
                            </button></a>
                        <a *ngIf="env.envType === enums.UNIVERSAL_REALTY_SITE"
                            href="https://universalrealty.appfolio.com/oportal/users/log_in"
                            (click)="analyticsService.sendGAActionEvent('homeowner-portal')" target="_blank"><button
                                mat-menu-item>Homeowner Portal </button></a>
                        <a *ngIf="env.envType === enums.UNIVERSAL_REALTY_SITE"
                            href="https://universalrealty.appfolio.com/connect/users/sign_in"
                            (click)="analyticsService.sendGAActionEvent('tenant-portal')" target="_blank"><button
                                mat-menu-item>Tenant Portal </button></a>
                        <a (click)="toProfile()"><button mat-menu-item>My Profile</button></a>
                        <a *ngIf="authenticated" (click)="logOut()"><button mat-menu-item>Logout</button></a>
                    </mat-menu>
                </div>
            </div>
        </div>
    </header>

    <!-- <div style="color: red">{{appService.isMobile}}</div> -->
    <!-- Static header  -->
    <header class="static-head header-wrapper non-mobile-static"
        *ngIf="!appService.isMobile && env.header.type === enums.HEADER_DROP_DOWN">
        <div class="header-container d-flex justify-content-between align-items-center w-100">
            <div class="d-flex header-text">
                <a class="header-text-link" [routerLink]="'/'">{{ env?.clientName }}</a><span
                    style="font-size: .8rem"></span>
                <!-- <div *ngIf="appService.isMobile">
                    <button mat-button [matMenuTriggerFor]="menu"><img src="assets/images/hamburger-menu.png" width="30"
                            height="30"></button>
                    <mat-menu #menu="matMenu">
                        <a [routerLink]="'/about-us'"><button mat-menu-item>About Us</button></a>
                        <button mat-menu-item>Item 2</button>
                    </mat-menu>
                </div> -->
            </div>

            <nav class="nav-links">
                <ul class="d-flex align-items-normal" style="margin-bottom: 0px">
                    <div class="d-flex align-items-center pt-2">
                        <li class="position-relative">
                            <a class="menu-title text-uppercase" [routerLink]="'/about-us'">{{ "About" | translate
                                }}</a>
                            <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                                <li><a [routerLink]="'/about-us'">About Us</a></li>
                                <li><a [routerLink]="'/testimonials'">Testimonials</a></li>
                            </ul>
                        </li>
                        <li class="position-relative">
                            <a class="menu-title text-uppercase" [routerLink]="'/search-property'">{{ "Properties" |
                                translate
                                }}</a>
                            <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                                <li><a [routerLink]="'/search-property'">Search</a></li>
                                <li><a [routerLink]="'/search-property'"
                                        [queryParams]="{ status: 'Featured' }">Featured</a>
                                </li>
                            </ul>
                        </li>
                        <li class="position-relative">
                            <a class="menu-title text-uppercase" [routerLink]="'/communities'">{{ "Communities" |
                                translate
                                }}</a>
                            <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                                <li *ngFor="let c of communityData"><a
                                        [routerLink]="['/community/', c.city.toLowerCase()]">{{ c.city
                                        | translate }}</a></li>
                            </ul>
                        </li>
                        <li class="position-relative">
                            <a class="menu-title text-uppercase" [routerLink]="'/buyers-process'">{{ "Buyers" |
                                translate
                                }}</a>
                            <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                                <li><a [routerLink]="'/buyers-process'">{{ "Process Roadmap" | translate }}</a></li>
                                <li><a [routerLink]="'/search-property'">{{ "Search Property" | translate }}</a>
                                </li>
                            </ul>
                        </li>
                        <li class="position-relative">
                            <a class="menu-title text-uppercase" [routerLink]="'/sellers-process'">{{ "Sellers" |
                                translate
                                }}</a>
                        </li>
                        <li class="position-relative">
                            <a class="menu-title text-uppercase" [routerLink]="'/blogs'">{{ "Blogs" | translate
                                }}</a>
                        </li>
                        <li *ngIf="env.envType === enums.UNIVERSAL_REALTY_SITE" class="position-relative">
                            <a class="menu-title text-uppercase" [routerLink]="'/property-management'">
                                {{ "Property Management" | translate }}</a>
                            <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                                <li><a [routerLink]="'/property-management'">{{ "Services" | translate }}</a></li>
                                <li><a target="_blank"
                                        href="https://universalrealty.appfolio.com/oportal/users/log_in">{{
                                        "Homeowner Portal" | translate }}</a>
                                </li>
                                <li><a target="_blank"
                                        href="https://universalrealty.appfolio.com/connect/users/sign_in">{{
                                        "Tenant Portal" | translate }}</a>
                                </li>
                            </ul>
                        </li>
                    </div>

                    <li *ngIf="!authenticated" class="position-relative profile-icon">
                        <button class="btn btn-secondary" style="padding: 3px 6px" (click)="openProfile()">
                            <img src="assets/images/profile-circle-white.png" width="30px" height="30px" />
                        </button>
                    </li>

                    <li *ngIf="authenticated" class="position-relative profile-icon">
                        <a class="menu-title text-uppercase">
                            <button *ngIf="userFullName"
                                class="d-flex justify-content-center align-items-center btn btn-secondary menu-title text-uppercase"
                                style="padding: 3px 6px; background: rgba(0, 0, 0, 0); border: none">
                                <!-- <img src="assets/images/profile-circle-white.png" width="30px" height="30px" /> -->
                                <span class="user-initial">{{ userInitial }}</span>
                                <!-- <span style="font-size: 1rem">+</span> -->
                            </button>
                            <button *ngIf="!userFullName"
                                class="d-flex justify-content-center align-items-center btn btn-secondary menu-title text-uppercase"
                                style="padding: 3px 6px" (click)="openProfile()">
                                <img src="assets/images/profile-circle-white.png" width="30px" height="30px" />
                                <span style="font-size: 1rem">+</span>
                            </button>
                        </a>
                        <ul class="mt-3 submenu d-flex flex-column justify-content-center align-items-center">
                            <li><a [routerLink]="'/my-profile'">My Profile</a></li>
                            <li><a (click)="logOut()" style="cursor: pointer">Logout</a>
                            </li>
                        </ul>
                    </li>


                    <li class="dropdown lang-select">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{ localeNameOnPage }}
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngIf="localeName !== 'EN'"><a class="dropdown-item"
                                    (click)="setLocale('en')">English</a></li>
                            <li *ngIf="localeName !== '简体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-CN')">简体中文</a>
                            </li>
                            <li *ngIf="localeName !== '繁体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-HK')">繁体中文</a>
                            </li>
                        </ul>
                    </li>

                </ul>
            </nav>
        </div>
    </header>

    <!-- Drop down or transition  -->
    <header *ngIf="!appService.isMobile" class="head" [class]="setHeaderType()">
        <div class="d-flex justify-content-between align-items-start w-100">
            <div class="header-text">
                <a class="header-text-link" [routerLink]="'/'">{{ env?.clientName }}</a>
            </div>

            <nav class="nav-links">
                <ul class="d-flex align-items-normal">
                    <li class="position-relative">
                        <a class="menu-title text-uppercase" [routerLink]="'/about-us'">{{ "About" | translate }}</a>
                        <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                            <li><a [routerLink]="'/about-us'">About Us</a></li>
                            <li><a [routerLink]="'/testimonials'">Testimonials</a></li>
                        </ul>
                    </li>
                    <li class="position-relative">
                        <a class="menu-title text-uppercase" [routerLink]="'/search-property'">{{ "Properties" |
                            translate }}</a>
                        <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                            <li><a [routerLink]="'/search-property'">Search</a></li>
                            <li><a [routerLink]="'/search-property'" [queryParams]="{ status: 'Featured' }">Featured</a>
                            </li>
                        </ul>
                    </li>
                    <li class="position-relative">
                        <a class="menu-title text-uppercase" [routerLink]="'/communities'">{{ "Communities" | translate
                            }}</a>
                        <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                            <li *ngFor="let c of communityData"><a
                                    [routerLink]="['/community/', c.city.toLowerCase()]">{{ c.city
                                    | translate }}</a></li>
                        </ul>
                    </li>
                    <li class="position-relative">
                        <a class="menu-title text-uppercase" [routerLink]="'/buyers-process'">{{ "Buyers" | translate
                            }}</a>
                        <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                            <li><a [routerLink]="'/buyers-process'">Process Roadmap</a></li>
                            <li><a [routerLink]="'/search-property'">Search Property</a>
                            </li>
                        </ul>
                    </li>
                    <li class="position-relative">
                        <a class="menu-title text-uppercase" [routerLink]="'/sellers-process'">{{ "Sellers" | translate
                            }}</a>
                    </li>
                    <li class="position-relative">
                        <a class="menu-title text-uppercase" [routerLink]="'/blogs'">{{ "Blogs" | translate
                            }}</a>
                    </li>
                    <li *ngIf="env.envType === enums.UNIVERSAL_REALTY_SITE" class="position-relative">
                        <a class="menu-title text-uppercase" [routerLink]="'/property-management'">
                            {{ "Property Management" | translate }}</a>
                        <ul class="submenu d-flex flex-column justify-content-center align-items-center">
                            <li><a [routerLink]="'/property-management'">Services</a></li>
                            <li><a target="_blank"
                                    href="https://universalrealty.appfolio.com/oportal/users/log_in">Homeowner
                                    Portal</a>
                            </li>
                            <li><a target="_blank"
                                    href="https://universalrealty.appfolio.com/connect/users/sign_in">Tenant
                                    Portal</a>
                            </li>
                        </ul>
                    </li>

                    <li *ngIf="!authenticated" class="position-relative profile-icon">
                        <button class="btn btn-secondary" style="padding: 3px 6px" (click)="openProfile()">
                            <img src="assets/images/profile-circle-white.png" width="30px" height="30px" />
                        </button>
                    </li>

                    <li *ngIf="authenticated" class="position-relative profile-icon">
                        <a class="menu-title text-uppercase">
                            <button *ngIf="userFullName"
                                class="d-flex justify-content-center align-items-center btn btn-secondary menu-title text-uppercase"
                                style="padding: 3px 6px; background: rgba(0, 0, 0, 0); border: none">
                                <!-- <img src="assets/images/profile-circle-white.png" width="30px" height="30px" /> -->
                                <span class="user-initial">{{ userInitial }}</span>
                                <!-- <span style="font-size: 1rem">+</span> -->
                            </button>
                            <button *ngIf="!userFullName"
                                class="d-flex justify-content-center align-items-center btn btn-secondary menu-title text-uppercase"
                                style="padding: 3px 6px" (click)="openProfile()">
                                <img src="assets/images/profile-circle-white.png" width="30px" height="30px" />
                                <span style="font-size: 1rem">+</span>
                            </button>
                        </a>
                        <ul class="mt-3 submenu d-flex flex-column justify-content-center align-items-center">
                            <li><a [routerLink]="'/my-profile'">My Profile</a></li>
                            <li><a (click)="logOut()" style="cursor: pointer">Logout</a>
                            </li>
                        </ul>
                    </li>

                    <li class="dropdown lang-select">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{ localeNameOnPage }}
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngIf="localeName !== 'EN'"><a class="dropdown-item"
                                    (click)="setLocale('en')">English</a></li>
                            <li *ngIf="localeName !== '简体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-CN')">简体中文</a>
                            </li>
                            <li *ngIf="localeName !== '繁体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-HK')">繁体中文</a>
                            </li>
                        </ul>
                    </li>

                </ul>
            </nav>
        </div>
    </header>

</div>

<div *ngIf="env.envType === enums.HOUSEHUB_SITE">

    <!-- Mobile header  -->
    <header class="static-head w-100 header-wrapper" *ngIf="appService.isMobile">
        <div class="d-flex justify-content-start align-items-end w-100">
            <div class="d-flex header-text">
                <div class="dropdown lang-select">
                    <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                        {{ localeNameOnPage }}
                    </button>
                    <ul class="dropdown-menu">
                        <li *ngIf="localeName !== 'EN'"><a class="dropdown-item" (click)="setLocale('en')">English</a>
                        </li>
                        <li *ngIf="localeName !== '简体'"><a class="dropdown-item" (click)="setLocale('zh-CN')">简体中文</a>
                        </li>
                        <li *ngIf="localeName !== '繁体'"><a class="dropdown-item" (click)="setLocale('zh-HK')">繁体中文</a>
                        </li>
                    </ul>
                </div>
                <a class="header-text-link" [routerLink]="'/'">{{ env?.clientName }}</a><span
                    style="font-size: .8rem"></span>
                <div *ngIf="appService.isMobile">
                    <button mat-button [matMenuTriggerFor]="beforeMenu"><img src="assets/images/hamburger-menu.png"
                            width="30" height="30"
                            [style]="appService.isMobile ? { 'background-color': 'white' } : ''"></button>
                    <mat-menu #beforeMenu="matMenu" xPosition="before">
                        <a [routerLink]="'/pricing'"><button mat-menu-item>Pricing&nbsp;&nbsp;&nbsp;&nbsp;
                            </button></a>
                        <a *ngIf="!authenticated" [routerLink]="'/login'"><button
                                mat-menu-item>Login&nbsp;&nbsp;&nbsp;&nbsp;
                            </button></a>
                        <a *ngIf="authenticated" [routerLink]="'/my-profile'"><button mat-menu-item>My
                                Profile&nbsp;&nbsp;&nbsp;&nbsp;
                            </button></a>
                        <a *ngIf="authenticated" [routerLink]="'/login'" (click)="logOut()"><button
                                mat-menu-item>Logout&nbsp;&nbsp;&nbsp;&nbsp;
                            </button></a>

                    </mat-menu>
                </div>
            </div>
        </div>
    </header>

    <header class="static-head non-mobile-static" style="position: absolute; z-index: 100; width: 100vw"
        *ngIf="env.header.type === enums.HEADER_DROP_DOWN && !appService.isMobile">
        <div class="header-container d-flex justify-content-between align-items-end w-100"
            style="height: 80px; margin-left: 20px; margin-bottom: 0px; padding-bottom: 10px">
            <div class="header-text">
                <a class="header-text-link" [routerLink]="'/'">{{ env?.clientName }}</a>
            </div>

            <nav class="hh-nav">
                <ul class="d-flex align-items-end" style="margin-bottom: 0px">

                    <li *ngIf="!authenticated" class="position-relative">
                        <a class="menu-title" [routerLink]="'/login'">{{ "Login" | translate }}</a>
                    </li>

                    <li class="position-relative">
                        <a class="menu-title" [routerLink]="'/pricing'">{{ "Pricing" | translate }}</a>
                    </li>

                    <li *ngIf="authenticated" class="position-relative">
                        <a class="menu-title" [routerLink]="'my-profile'">{{ "My Profile" | translate }}</a>
                    </li>

                    <li *ngIf="authenticated" class="position-relative">
                        <a class="menu-title" [routerLink]="'login'" (click)="logOut()">{{ "Logout" | translate }}</a>
                    </li>

                    <div class="dropdown position-relative">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{ localeNameOnPage }}
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngIf="localeName !== 'EN'"><a class="dropdown-item"
                                    (click)="setLocale('en')">English</a></li>
                            <li *ngIf="localeName !== '简体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-CN')">简体中文</a>
                            </li>
                            <li *ngIf="localeName !== '繁体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-HK')">繁体中文</a>
                            </li>
                        </ul>
                    </div>

                </ul>
            </nav>
        </div>
    </header>

    <header *ngIf="!appService.isMobile" class="head" [class]="setHeaderType()">
        <div class="d-flex justify-content-start w-100">
            <div class="header-text">
                <a class="header-text-link" [routerLink]="'/'">{{ env?.clientName }}</a>
            </div>

            <nav class="hh-nav">
                <ul class="d-flex">
                    <li *ngIf="!authenticated" class="position-relative">
                        <a class="menu-title" [routerLink]="'/login'">{{ "Login" | translate }}</a>
                    </li>

                    <li class="position-relative">
                        <a class="menu-title" [routerLink]="'/pricing'">{{ "Pricing" | translate }}</a>
                    </li>

                    <li *ngIf="authenticated" class="position-relative">
                        <a class="menu-title" [routerLink]="'my-profile'">{{ "My Profile" | translate }}</a>
                    </li>

                    <li *ngIf="authenticated" class="position-relative">
                        <a class="menu-title" [routerLink]="'login'" (click)="logOut()">{{ "Logout" | translate }}</a>
                    </li>

                    <div class="dropdown posiition-relative">
                        <button class="btn btn-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown"
                            aria-expanded="false">
                            {{ localeNameOnPage }}
                        </button>
                        <ul class="dropdown-menu">
                            <li *ngIf="localeName !== 'EN'"><a class="dropdown-item"
                                    (click)="setLocale('en')">English</a></li>
                            <li *ngIf="localeName !== '简体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-CN')">简体中文</a>
                            </li>
                            <li *ngIf="localeName !== '繁体'"><a class="dropdown-item"
                                    (click)="setLocale('zh-HK')">繁体中文</a>
                            </li>
                        </ul>
                    </div>

                </ul>
            </nav>
        </div>
    </header>
</div>