<div class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative text-uppercase">
                <b>what&nbsp;our&nbsp;</b> <br><span style="color: #e11b39">clients are saying</span>
            </div>
            <span class="dash"></span>
            <div class="content mt-1">
                <div *ngFor="let testomonial of testimonials; let i = index">
                    <div class="card-wrapper"
                        [class]="{'card-wrapper-even': i % 2 === 0, 'card-wrapper-odd': i % 2 !== 0}">
                        <div class="card-star">
                            <img *ngFor="let i of [0,1,2,3,4]" src="assets/images/star-yellow.png" width="30px"
                                height="30px" />
                        </div>
                        <div class="card-content mt-3">{{ testomonial.message }}</div>
                        <div class="card-client">- {{ testomonial.author }}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <span class="dash"></span>

    <app-contact-us class="contact-us-wrapper" [showMap]="false"></app-contact-us>


</div>

<app-footer class="footer"></app-footer>