import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';
import { communityData } from "../communities/communities-data";


@Component({
  selector: 'app-sitemap',
  standalone: true,
  imports: [
    CommonModule,
    FooterComponent,
    RouterModule,
  ],
  templateUrl: './sitemap.component.html',
  styleUrl: './sitemap.component.scss'
})
export class SitemapComponent {

  environment = environment;
  communityData = communityData;

  ENUMS = ENUMS;

  constructor(
    private appService: AppServiceService,
  ) {

  }

  ngOnInit(): void {
    this.appService.scrollToTop();
  }

}
