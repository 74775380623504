import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router } from '@angular/router';
import { environment } from '../../env/environment';

@Component({
  selector: 'app-cookie-consent',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
  ],
  templateUrl: './cookie-consent.component.html',
  styleUrl: './cookie-consent.component.scss'
})
export class CookieConsentComponent {

  env = environment;
  showConsentBanner = false;

  userSelection = {
    Analytics: true,
    Preferences: true,
    Marketing: false,
  }

  ngOnInit() {
    // Only show full banner if user hasn't made a selection. After selection is made,
    // user can reopen it from cookie icon on left bottom.
    this.checkToShowFullBanner();
  }

  // Referencing: https://github.com/googleanalytics/ga4-tutorials/blob/main/src/public/partials/consent.eta
  // https://www.youtube.com/watch?v=MqAEbshMv84

  checkToShowFullBanner() {
    if (localStorage.getItem('consentMode') !== null) {
      this.showConsentBanner = false;
    } else {
      this.showConsentBanner = true;
    }
  }

  hideBanner() {
    this.showConsentBanner = false;
  }

  acceptAll() {
    this.setConsent({
      necessary: true,
      analytics: true,
      preferences: true,
      marketing: true
    });
    this.hideBanner();
  }

  acceptSome() {
    this.setConsent({
      necessary: true,
      analytics: this.userSelection.Analytics,
      preferences: this.userSelection.Preferences,
      marketing: this.userSelection.Marketing
    });
    this.hideBanner();
    console.log(this.userSelection)
  }

  rejectAll() {
    this.setConsent({
      necessary: false,
      analytics: false,
      preferences: false,
      marketing: false
    });
    this.hideBanner();
  }

  updateSelection(type: string, event: any) {
    if (event && event.target) {
      switch (type) {
        case 'Analytics':
          this.userSelection.Analytics = !this.userSelection.Analytics;
          break;
        case 'Preferences':
          this.userSelection.Preferences = !this.userSelection.Preferences;
          break;
        case 'Marketing':
          this.userSelection.Marketing = !this.userSelection.Marketing;
          break;
      }
    }
    console.log(this.userSelection)
  }

  setConsent(consent: any) {
    const consentMode: any = {
      'functionality_storage': consent.necessary ? 'granted' : 'denied',
      'security_storage': consent.necessary ? 'granted' : 'denied',
      'ad_storage': consent.marketing ? 'granted' : 'denied',
      'analytics_storage': consent.analytics ? 'granted' : 'denied',
      'personalization_storage': consent.preferences ? 'granted' : 'denied',
    };
    gtag('consent', 'update', consentMode);
    localStorage.setItem('consentMode', JSON.stringify(consentMode));
  }
}
