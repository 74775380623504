import { Component, OnInit, inject } from '@angular/core';
import { CommonModule, DOCUMENT } from '@angular/common';
import { JumbotronVideoComponent } from '../video/jumbotron-video/jumbotron-video.component';
import { ContactUsComponent } from '../contact-us/contact-us.component';
import { AboutUsComponent } from '../about-us/about-us.component';
import { FeaturedPropertiesComponent } from '../featured-properties/featured-properties.component';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { FooterComponent } from '../footer/footer.component';
import { AppServiceService } from '../services/app-service.service';
import { HhJumbotronComponent } from '../hh-jumbotron/hh-jumbotron.component';
@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    CommonModule,
    JumbotronVideoComponent,
    ContactUsComponent,
    FeaturedPropertiesComponent,
    AboutUsComponent,
    FooterComponent,
    HhJumbotronComponent,
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss',
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainComponent implements OnInit {
  pathToJumbotronVideo: string;
  environment = environment;
  ENUMS = ENUMS;

  email: any;
  phone: any;

  constructor(
    public appService: AppServiceService,
  ) {
    // this.pathToJumbotronVideo = 'assets/videos/city-park.mp4';
    this.pathToJumbotronVideo = 'assets/videos/luxury_community.mp4';

  }

  ngOnInit(): void {
    this.appService.scrollToTop();

    if (environment.envType === ENUMS.HOUSEHUB_SITE) {
      this.email = 'househubnc@gmail.com';
      this.phone = '';
    } else {
      this.email = environment.clientEmail;
      this.phone = environment.clientPhone;
    }
  }
}
