import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';

import { Amplify } from 'aws-amplify';

import { Hub } from "aws-amplify/utils";
import { signInWithRedirect, getCurrentUser, AuthUser } from "aws-amplify/auth";


// import amplifyconfig from './amplifyconfiguration.json';
// Amplify.configure(amplifyconfig);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));


// Ref: 
// Create Amplify project https://docs.amplify.aws/gen1/angular/start/getting-started/installation/, steps up to Deploy and host app
// https://www.youtube.com/watch?v=pKA8qXINjkU
// https://www.youtube.com/watch?v=pKA8qXINjkU
// On AWS Cognito, with each new Social provider
// 1. manually add Google to Pool, under Signin-Experience
// 2. under App Intergration, bottom App Client list, click on client, manually add Google under Host UI

// With each amplify publish,
// 1. manually add Social to the pool App Integration /  hosted UI client(web)

// Update redirect
// 1. Update in main.ts redirectSignin and redirectSignOut
// 2. Update cli-inputs.json's oAuthMetadata CallbackURLs and LogoutURLs

// Amplify.configure({
//   Auth: {
//     Cognito: {
//       loginWith: {
//         oauth: {
//           domain: "hhamplifybf7f3e6a-bf7f3e6a-dev.auth.us-east-1.amazoncognito.com",
//           // domain: "househub-userpool-12345.auth.us-east-1.amazoncognito.com",
//           scopes: [
//             "phone",
//             "email",
//             "openid",
//             "profile",
//             "aws.cognito.signin.user.admin"
//           ],
//           redirectSignIn: ["http://localhost:4200/properties/"],
//           redirectSignOut: ["http://localhost:4200/login/"],
//           responseType: "code"
//         },
//       },
//       userPoolId: 'us-east-1_6cFvxxTXj',
//       userPoolClientId: '6skqf30ubk3kullfg9epr6j5vb'
//       // userPoolId: 'us-east-1_9HUM2wq2Z',
//       // userPoolClientId: '6o1lfbd2670ouhamknbd8mvp7k'
//     }
//   }
// });

// Hub.listen("auth", ({ payload }) => {
//   switch (payload.event) {
//     case "signInWithRedirect":
//       getCurrentUser().then(user => {
//         console.log(user.username);
//       });
//       break;
//     case "signInWithRedirect_failure":
//       // handle sign in failure
//       break;
//     case "customOAuthState":
//       const state = payload.data; // this will be customState provided on signInWithRedirect function
//       console.log(state);
//       break;
//   }
// });

// function handleSignInClick(customState: string) {
//   signInWithRedirect({
//     provider: "Google",
//     customState
//   });
// }