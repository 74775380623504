<div class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative">
                PREPARING TO SELL
            </div>
            <span class="dash"></span>
            <div class="content mt-5 realtor-description">
                <p>
                    Our team of local experts is ready to guide you through the selling process. From setting the price
                    to creating marketing plans for traditional media and Web, we’ve got a proven plan that will match
                    your home with the right buyers for the right price. Our experienced agents specialize in the
                    triangle real estate market and are prepared to sell your home to the right buyer for the best
                    price.
                </p>

                <mat-stepper labelPosition="bottom" #stepper orientation="vertical">
                    <mat-step>
                        <ng-template matStepLabel>Choose a Listing Agent</ng-template>
                        <p>A listing agent will represent seller and have a fiduciary responsible for looking out for
                            seller’s best interests.

                            Interview agents and ask questions about the listing agreement, including the length of time
                            the property will be listed.</p>
                        <div>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Find Out How Much Property is Worth</ng-template>
                        <p>Price the property in line with sold properties identified in a comparative market analysis.
                            A seller's biggest mistake is to overprice a property.

                            Consider whether the market is hot, cold or neutral, and price according to the market
                            temperature.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Get Property Ready for Sale</ng-template>
                        <p>Prepare the property for sale by cleaning, decluttering and improving curb appeal.

                            Hire a professional stager to stage the property, or ask listing agent for advice.

                            Make repairs before selling as need. Consult with listing agent.

                            Protect seller’s privacy while the property is on the market.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Prepare Seller Disclosures</ng-template>
                        <p>If seller is aware of material facts, disclose them.

                            If seller belong to a home owner association, additional documentation will be required.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Market the Property</ng-template>
                        <p>Identify the sizzling selling points and choose advertising words to sell.

                            Take quality photographs.

                            Approve listing agent's marketing plan.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Show the Property</ng-template>
                        <p>Seller will get more showings if seller let agents use a lockbox.

                            Generally, the property will show better if seller sell in spring than sell in winter.
                            Selling during the holidays could likely result in a lower sales price.

                            Often seller only has one chance -- and sometimes only 30 seconds -- to make a good first
                            impression.

                            Prepare for an open house and use the approach sparingly.

                            Ask for buyer feedback so seller can adjust listing price, condition or marketing strategies
                            accordingly.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Offers and Negotiate</ng-template>
                        <p>Make certain that buyers use the right form for writing a purchase offer.

                            Even if seller receive a lowball offer, negotiate by issuing a counter offer. Don't ignore
                            offers.

                            Consider making a counter offer contingent on buying a property, if market conditions
                            warrant.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Cooperate with Property Inspection</ng-template>
                        <p>Get ready for the property inspectors.

                            Prepare the attic and basement for inspection, too. Move stuff away from the walls in the
                            garage.

                            Also, prepare for the final walk-through inspection which takes place a few days before, or
                            the morning of, closing.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Negotiate Request for Repair</ng-template>
                        <p>Sellers do not have to accept buyer's request for repair; however, buyers can also cancel the
                            contract.

                            Seller might receive a copy of the property inspection report if the buyers request repairs.

                            If seller does not choose to make repairs, consider offering buyer a closing cost credit.
                        </p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Closing</ng-template>
                        <p>Seller will sign title and escrow documents near or at closing. Bring a valid picture
                            identification.

                            Closing attorney will notify seller and listing agent when it the deed is recorded.

                            Depending on buyer's possession rights specified in the contract, seller may be required to
                            move on the day the property closes, prior or after.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button (click)="stepper.reset()">Reset</button>
                        </div>
                    </mat-step>
                </mat-stepper>
            </div>
        </div>
    </div>

    <span class="dash"></span>

    <app-contact-us class="contact-us-wrapper" [showMap]="environment.enableGoogleMaps"></app-contact-us>


</div>

<app-footer class="footer"></app-footer>