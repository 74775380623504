import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { TranslateModule } from '@ngx-translate/core';
import { AppServiceService } from '../services/app-service.service';

@Component({
  selector: 'app-hh-jumbotron',
  standalone: true,
  imports: [
    MatCardModule,
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './hh-jumbotron.component.html',
  styleUrl: './hh-jumbotron.component.scss'
})
export class HhJumbotronComponent implements OnInit {

  imageUrl: string;
  locale = 'English';

  constructor(
    public appService: AppServiceService,
  ) {
    this.imageUrl = 'assets/images/b-sign.jpg';
  }

  ngOnInit() {
    this.setTransition();

    this.appService.getData().subscribe((data: any) => {
      if (data && data.type === 'translate') {
        this.locale = data.value;

        this.setTransition();
      }
    })
  }

  setTransition() {
    if (typeof document !== 'undefined') {
      let that = this;
      $(document).ready(function () {
        // var background = $(".background");
        var expertise = $(".expertise-wrapper");
        // var text = $(".header-text")

        $(window).scroll(function () {
          var scroll: any = $(window).scrollTop();
          if (scroll >= 300) {
            expertise.addClass("scrolled");
          }
        })
      });
    }
  }

}
