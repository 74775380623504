<div class="outter-wrapper d-flex flex-column justify-content-center position-relative">
    <div class="d-flex justify-content-center">
        <div class="text-wrapper mt-5">
            <div class="title relative">
                PREPARING TO BUY
            </div>
            <span class="dash"></span>
            <div class="content mt-5 realtor-description">
                <p>
                    Our team of local experts is ready to guide you through the buying process. We are committed to
                    quality professional service to help you understand and feel at ease throughout the buying process.
                    Our trained and licensed agents specialize in the triangle real estate market and are prepared to
                    find the right property and get the best price.
                </p>

                <mat-stepper labelPosition="bottom" #stepper orientation="vertical">
                    <mat-step>
                        <ng-template matStepLabel>Decide to Buy</ng-template>
                        <p>Determine your preferred location, type of home (e.g. single-family home, townhome, or
                            condo), size and budget. Familiarize yourself with your personal finances. Review your
                            budget and begin to eliminate as much debt as possible, and have a down payment ready.</p>
                        <div>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>
                    <mat-step>
                        <ng-template matStepLabel>Get Pre-approved</ng-template>
                        <p>Before you start looking for a home, you will need to know how much you can actually spend.
                            The best way to do that is to get prequalified for a mortgage. A pre-approval letter is
                            usually required for offer submission.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Search for Homes</ng-template>
                        <p>Start looking for homes within your price range. Most people choose to work with a real
                            estate agent. Agents can provide you with helpful information on homes and neighborhoods
                            that isn’t easily accessible to the general public. Their knowledge of the home buying
                            process, negotiating skills, and familiarity with the area can be very valuable to your
                            decision. Also, it doesn’t cost you anything to use an agent – they’re compensated from the
                            commission paid by the seller of the house.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Offer</ng-template>
                        <p>Take as much time as you need to find the right home. Once you choose one, work with your
                            agent to prepare a fair offer based on the value of comparable homes in the same
                            neighborhood. Key offer terms include purchase price, due diligence fee, earnest money,
                            length of due diligence period, and closing day.

                            Your agent will then prepare the Offer to Purchase Contract for you to sign. Along with the
                            offer, a photocopy or image of the due diligence check is required by some sellers. </p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Negotiation</ng-template>
                        <p>Seller will respond by accepting, countering or rejecting your offer. Negotiation takes place
                            while seller or buyer counters. When it comes to offer negotiation, a lot depends on the
                            market. The local market condition is the single most important factor in negotiation
                            strategy. Your agent should know the neighborhood well and be able to tell you what kind of
                            market you are in.

                            Once the two parties reach an agreement and sign, the home is “under contract” with a
                            pending/contingent status, and the due diligence fee must be delivered to the seller
                            immediately. In North Carolina, in most cases, the due diligence fee is non-refundable.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Inspection</ng-template>
                        <p>Within a few days of your offer being accepted, your agent will help arrange inspections.
                            Work with your agent to determine if there is any other inspection (e.g. pest, radon) needed
                            in addition to the general home inspection. You will receive a report on the home
                            inspector’s findings. You can then decide on which items you want to ask the seller to fix
                            before closing.

                            Before due diligence period ends, if any major problems are found or for other reasons, the
                            buyer has the right to terminate the purchase contract and get the earnest money back. </p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Earnest Money</ng-template>
                        <p>Soon after the offer is accepted, buyer also needs to pay the earnest money. This fund is
                            given to an agreed-upon escrow agent - typically this is the closing attorney, or one of the
                            two real estate brokerages involved in the transaction who deposits it into a trust account
                            until closing.

                            The earnest money deposit is usually larger than the due diligence fee. Like the due
                            diligence fee, this deposit protects the seller and helps ensure the buyer is “in earnest”
                            about purchasing their property. If buyer decides, BEFORE the end of the due diligence
                            period, that they no longer want to move forward with the purchase, they will lose the due
                            diligence money and get the earnest money back. If, however, buyer decides to pull out
                            before closing but AFTER the end of the due diligence period, they will lose both their
                            earnest money and due diligence money.</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Appraisal</ng-template>
                        <p>Lenders will arrange for an appraiser to provide an independent estimate of the value of the
                            property you are buying. The appraiser is a member of a third-party company and is not
                            directly associated with the lender. If the appraisal comes in low, buyer might need to make
                            up the difference in cash at closing (i.e. the down pay amount will go up.)</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Preparing to Close</ng-template>
                        <p>• Housing insurance: your lender will typically require that you purchase home insurance to
                            be effective on the closing date. As you start the loan application, start looking at home
                            insurance offers.

                            • Final Walk-through: prior to closing, your agent will arrange a final walk-through with
                            you. The goal of the walk-through is to ensure the property's condition hasn't changed since
                            your last visit, that any agreed-upon repairs have been made.

                            • Fund wiring for closing: prior to closing, buyer will receive the Closing Disclosure
                            (CD)/HUD-1 from closing attorney which will indicate the amount to be wired to attorney.
                            Review the CD for accuracy, and wire fund to the closing attorney.

                            • Utility accounts transfer: contact city/town/utility company to transfer accounts to your
                            name to be effective starting from the closing date. </p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button matStepperNext>Next</button>
                        </div>
                    </mat-step>

                    <mat-step>
                        <ng-template matStepLabel>Closing</ng-template>
                        <p>At closing, you will sign the paperwork required to complete the purchase, including your
                            loan documents. It typically takes less than 24 hours for your loan to be funded after the
                            paperwork is returned to the lender. Once the fund is delivered to the seller, you are ready
                            to move into your new home!</p>
                        <div>
                            <button mat-button matStepperPrevious>Back</button>
                            <button mat-button (click)="stepper.reset()">Reset</button>
                        </div>
                    </mat-step>
                </mat-stepper>
            </div>
        </div>
    </div>

    <span class="dash"></span>

    <app-contact-us class="contact-us-wrapper" [showMap]="environment.enableGoogleMaps"></app-contact-us>


</div>

<app-footer class="footer"></app-footer>