import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { SearchPropertyComponent } from './search-property/search-property.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { BuyersProcessComponent } from './buyers-process/buyers-process.component';
import { SellersProcessComponent } from './sellers-process/sellers-process.component';
import { PropertyManagementComponent } from './property-management/property-management.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { CommunitiesComponent } from './communities/communities.component';
import { HhPricingComponent } from './hh-pricing/hh-pricing.component';
import { AuthService } from './services/auth.service';
import { TestimonialComponent } from './testimonial/testimonial.component';
import { TermsOfServiceComponent } from './terms-of-service/terms-of-service.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { LoginComponent } from './login/login.component';
import { FavPropertiesComponent } from './fav-properties/fav-properties.component';
import { AuthGuard } from './services/auth.guard.service';
import { MyProfileComponent } from './my-profile/my-profile.component';
import { BlogComponent } from './blog/blog.component';
import { UnsubscribeComponent } from './unsubscribe/unsubscribe.component';
import { WorkOrderComponent } from './work-order/work-order.component';
import { SubscribeComponent } from './subscribe/subscribe.component';

var auth: any = AuthGuard;

export const routes: Routes = [
    // { path: '', pathMatch: 'full', redirectTo: '/' },
    { path: '', component: MainComponent },
    // { path: 'property-details/:mlsId', component: PropertyDetailsComponent },
    {
        path: 'property-details/:mlsId', loadComponent: () =>
            import('./property-details/property-details.component').then(m => m.PropertyDetailsComponent),
    },
    // { path: 'search-property', component: SearchPropertyComponent },
    {
        path: 'search-property', loadComponent: () =>
            import('./search-property/search-property.component').then(m => m.SearchPropertyComponent),
    },
    // { path: 'about-us', component: AboutUsComponent },
    {
        path: 'about-us', loadComponent: () =>
            import('./about-us/about-us.component').then(m => m.AboutUsComponent),
    },
    { path: 'buyers-process', component: BuyersProcessComponent },
    { path: 'sellers-process', component: SellersProcessComponent },
    { path: 'property-management', component: PropertyManagementComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    // { path: 'community/:city', component: CommunitiesComponent },
    {
        path: 'community/:city', loadComponent: () =>
            import('./communities/communities.component').then(m => m.CommunitiesComponent),
    },
    // { path: 'communities', component: CommunitiesComponent, },
    {
        path: 'communities', loadComponent: () =>
            import('./communities/communities.component').then(m => m.CommunitiesComponent),
    },
    { path: 'testimonials', component: TestimonialComponent, },
    { path: 'sitemap', component: SitemapComponent, },
    // { path: 'my-profile', component: MyProfileComponent, canActivate: [auth] },
    {
        path: 'my-profile', loadComponent: () =>
            import('./my-profile/my-profile.component').then(m => m.MyProfileComponent), canActivate: [auth]
    },

    // HH paths
    { path: 'pricing', component: HhPricingComponent },
    { path: 'terms-of-service', component: TermsOfServiceComponent },
    { path: 'login', component: LoginComponent },
    { path: 'blogs', component: BlogComponent },
    { path: 'blogs-detail', component: BlogComponent },
    { path: 'unsubscribe', component: UnsubscribeComponent },
    { path: 'subscribe', component: SubscribeComponent },
    { path: 'properties', component: FavPropertiesComponent },

    // {
    //     path: '',
    //     redirectTo: '/properties',
    //     pathMatch: 'full',
    // },
    // {
    //     path: '**',
    //     redirectTo: '/properties',
    // },

];
