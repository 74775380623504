import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})

//Cognito
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    // if (!this.authService.isAuthenticated())
    //   this.router.navigate(["login"]);

    // return this.authService.isAuthenticated();

    let authenticated: boolean = true;

    this.authService.isAuthenticated().then((auth => {
      authenticated = auth;
      if (!authenticated) {
        this.router.navigate(["login"]);
        return false;
      }
      return authenticated;
    }))

    return authenticated;
  }
}

