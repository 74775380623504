import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { environment } from '../../env/environment';
import { ENUMS } from '../../models/constants';
import { RouterModule, ActivatedRoute, Router, } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AppServiceService } from '../services/app-service.service';
import { communityData } from "../communities/communities-data";
import { GoogleAnalyticsService } from '../services/google-analytics.service';
import { AuthService } from '../services/auth.service';

import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

import $ from 'jquery'
import { TranslateModule } from '@ngx-translate/core';
import { LoginComponent } from '../login/login.component';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    MatButtonModule, MatMenuModule
  ],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss'
})
export class HeaderComponent implements OnInit {
  env: any = environment;
  enums: any = ENUMS;
  localeName = 'EN';
  localeNameOnPage = 'EN';
  localeList = [];
  communityData = communityData;
  authenticated = false;
  userFullName: any = '';
  userInitial: any = '';

  constructor(
    private translate: TranslateService,
    public appService: AppServiceService,
    public analyticsService: GoogleAnalyticsService,
    private authService: AuthService,
    public dialog: MatDialog,
    private router: Router,
  ) {
    this.appService.checkMobile();
  }

  async ngOnInit() {
    this.appService.checkMobile();

    this.setHeaderTransition();

    if (this.appService.isMobile) {
      this.localeNameOnPage = 'EN';
    }

    this.authenticated = await this.authService.isAuthenticated();

    this.userFullName = await this.authService.getCurrentUserFullName();

    this.userInitial = await this.authService.getCurrentUserInitial();

    // this.authService.setupCheckTokenExpInterval();
  }

  toProfile() {
    if (this.authenticated) {
      this.router.navigate(['my-profile']);
    } else {
      this.openProfile();
    }
  }

  openProfile() {
    this.dialog.open(LoginComponent, {
      // width: '30%',
      panelClass: ['header-login-dialog']
    });
  }

  setHeaderType() {
    switch (this.env.header.type) {
      case this.enums.HEADER_DROP_DOWN:
        return 'drop-down';
      case this.enums.HEADER_TRANSITION_BACKGROUND:
        return 'transition';
    }

    return '';
  }

  setHeaderTransition() {
    if (typeof document !== 'undefined') {
      let that = this;
      $(document).ready(function () {
        // var background = $(".background");
        var header = $(".head");
        var staticHeader = $(".static-head");
        var text = $(".header-text")

        $(window).scroll(function () {
          var scroll: any = $(window).scrollTop();

          if (that.env.header.type === that.enums.HEADER_DROP_DOWN) {
            if (scroll >= 40) {
              header.addClass("scrolled");
              staticHeader.addClass("scrolled");
              // background.addClass("scrolled");
              text.addClass("scrolled");
            } else {
              header.removeClass("scrolled");
              staticHeader.removeClass("scrolled");
              // background.removeClass("scrolled");
              text.removeClass("scrolled");
            }
          }

          if (that.env.header.type === that.enums.HEADER_TRANSITION_BACKGROUND) {
            if (scroll >= 30) {
              header.addClass("scrolled");
            } else {
              header.removeClass("scrolled");

            }
          }
        })
      });
    }
  }

  setLocale(locale: string) {
    this.analyticsService.trackEvent(`i18n-${locale}`, locale, "user-action");

    switch (locale) {
      case 'zh-CN':
        this.localeName = '简体';
        break;
      case 'zh-HK':
        this.localeName = '繁体';
        break;
      default:
        this.localeName = 'EN';
    }
    this.localeNameOnPage = this.localeName;

    this.appService.sendData({ type: 'translate', value: this.localeName })
    this.translate.use(locale);
  }

  logOut() {
    this.authService.signOut();

    setTimeout(() => {
      this.router.navigate([`${this.router.url}`]);
    }, 800)
  }
}
